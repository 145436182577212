import './MenteeSearch.css';
import React, {Component}  from 'react';
import { Table, Button, Input, FormGroup, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import authService from '../../services/auth';
import coreService from '../../services/core';
import reportService from '../../services/report';

class MenteeSearch extends Component {
  constructor ( props ) {
    super( props );
    this.user = authService.getUserFromToken() || {};
    this.state = {
                  mentees: [],
                  showEmailList: false,
                  emailList: "",
                  careerExperiences: [], 
                  industryExperiences: [],
                  selectedCareerExp: "0",
                  selectedIndustryExp: "0",
                  searchPerformed: false
                };
  }

  async componentDidMount() {
    if (!this.user || this.user.userTypeId !== 3) {
      return;
    }
    const careerExperiences = await coreService.getPossibleCareerAreas();
    const industryExperiences = await coreService.getPossibleIndustries();

    careerExperiences.unshift({id: 0, value: ""})
    industryExperiences.unshift({id: 0, value: ""})

    this.setState({careerExperiences, industryExperiences});
  }

  exportEmails() {
      let emailList = this.state.mentees.map(m => m.EmailAddress).join(", ");
      this.setState({
          showEmailList: true,
          emailList
      });
  }

  handleChange = event => {
    this.setState({
          [event.target.id]: event.target.value
      })

  }

  async performSearch() {
    var mentees = await reportService.performMenteeSearch(this.state.selectedCareerExp, this.state.selectedIndustryExp);
    this.setState({mentees, searchPerformed: true});
}


  render () {
      let {showEmailList} = this.state;
      let rows = this.state.mentees.map((m, i) => {
         return (<tr key={i}>
                <td> {m.FirstName}
                 </td>
                 <td> {m.LastName}
                 </td>
                 <td> {m.EmailAddress}
                 </td>
                 </tr> )
      });
    return (
        <div>
        <FormGroup>
          <Label> Career Experience
            <Input id="selectedCareerExp" type="select" onChange={this.handleChange}>
                            {this.state.careerExperiences.map((e, key) => {
                                return <option key={key} value={e.id}>{e.text}</option>;
                            })}
            </Input>          
       </Label>
       <Label> Industry Experience
            <Input className="industryExp" id="selectedIndustryExp"  type="select" onChange={this.handleChange}>
                            {this.state.industryExperiences.map((e, key) => {
                                return <option key={key} value={e.id}>{e.text}</option>;
                            })}
            </Input>          
       </Label>
       <Button className="SearchButton" onClick={() => {this.performSearch()}}>Search</Button>
        </FormGroup>
       
        <Table hidden={!this.state.searchPerformed}>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
              {rows}
          </tbody>
      </Table>
      <Button
      hidden={!this.state.searchPerformed}
      name="btnExportEmailList"
      onClick={() => {this.exportEmails()}}
      >Get Email List
    </Button>
    <br/><br/>
         {showEmailList ? <textarea className="emailListTextArea" readOnly value={this.state.emailList}></textarea> : <div></div> }
      </div>
      );
    }
}

export default withRouter(MenteeSearch);