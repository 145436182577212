import './ActiveMenteeReport.css';
import React, {Component}  from 'react';
import { Table, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import authService from '../../services/auth';
import reportService from '../../services/report';
import userService from '../../services/user';

class InactiveMentorReport extends Component {
  constructor ( props ) {
    super( props );
    this.user = authService.getUserFromToken() || {};
    this.state = {
                  mentors: [],
                  showEmailList: false,
                  emailList: "",
                };
  }

  async componentDidMount() {
    if (!this.user || this.user.userTypeId !== 3) {
      return;
    }
    const mentors = await reportService.getInactiveMentors();
    this.setState({ mentors }); 
  }

  async activateMentor(userId) {
    console.log(this.state.mentors)

      console.log(userId);
    await userService.setUserActive(userId);
    const index = this.state.mentors.indexOf(m => { return m.UserId == userId});
    this.state.mentors.splice(index, 1);
    this.setState({mentors: this.state.mentors});
  }

  exportEmails() {
    let emailList = this.state.mentors.map(m => m.EmailAddress).join(", ");
    this.setState({showEmailList: true, emailList});
  }

  render () {
      let {showEmailList} = this.state;
      let rows = this.state.mentors.map((m, i) => {
         return (<tr key={i}>
                <td> {m.FirstName}
                 </td>
                 <td> {m.LastName}
                 </td>
                 <td> {m.EmailAddress}
                 </td>
                 <td>
                    <Button
                        name="btnExportEmailList"
                        onClick={() => {this.activateMentor(m.UserId)}}>Activate Mentor
                    </Button>
                </td>
                 </tr> )
      });
    return (
        <div>
        <Table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
              {rows}
          </tbody>
      </Table>
      <Button
        name="btnExportEmailList"
        onClick={() => {this.exportEmails()}}
        >Get Email List
      </Button>
    <br/><br/>
         {showEmailList ? <textarea className="emailListTextArea" readOnly value={this.state.emailList}></textarea> : <div></div> }
      </div>
      );
    }
}

export default withRouter(InactiveMentorReport);