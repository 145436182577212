import './MentorPreRegister.css';

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, FormGroup, Input, Label } from 'reactstrap';

class MentorPreRegister extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasPassion: false,
            hasFiveYearsWorkforce: false,
            hasWillingness: false,
            validMentor: false,
            invalidMentor: false,
            enableForm: true,
            showFailedMessage: false
        };        
    }

    attrsChanged(event) {
        this.setState({...event});
    }

    handleChange = event => {
        this.setState({
            [ event.target.name ]: event.target.checked
        });
    }

    handleSubmit = async ( event ) => {
        const{ hasPassion, hasFiveYearsWorkforce, hasWillingness } = this.state;
        if ([hasPassion, hasFiveYearsWorkforce, hasWillingness].every(atr => atr === true)) {
            this.setState({validMentor: true});
        } else {
            this.setState({showFailedMessage: true})
        }
    }

    render () {
        const { validMentor, showFailedMessage} = this.state;
        if (validMentor) {
            return <Redirect to={ `/register/mentor` } />;
        } 
        if (showFailedMessage) {
            return <Redirect to={ `/preregister/mentorcriterianotmet` } />;
        }

        return (
            <div>
                <div>
                    <h3>Mentor Registration</h3>
                    <br/>
                    <p>The Overwatch Partnership is currently accepting interested veteran Mentors in Ohio. <br/>
                        To become a Mentor in our program, Mentors must:</p>
                    <p> (Please select all that apply)</p>
                </div>
            <div className="MentorPreRegister">
                <form>
                    <FormGroup id="hasFiveYearsWorkforce" check>
                    <Label check>
                        <Input
                            autoFocus
                            type="checkbox"
                            name="hasFiveYearsWorkforce"
                            value={ this.state.hasFiveYearsWorkforce }
                            onChange={ this.handleChange } /> 
                        Have at least 5 years of professional experience in the workforce
                         </Label>
                    </FormGroup>

                    <FormGroup id="hasPassion" check>
                        <Label check>
                        <Input
                            type="checkbox"
                            name="hasPassion"
                            value={ this.state.hasPassion }
                            onChange={ this.handleChange }
                        />
                        Have a passion to provide mentoring and career development advice to veterans
                        </Label>
                    </FormGroup>

                    <FormGroup id="hasWillingness" check>
                        <Label check>
                        <Input
                            type="checkbox"
                            name="hasWillingness"
                            value={ this.state.hasWillingness }
                            onChange={ this.handleChange }
                        />
                        Be willing to meet with assigned Mentee at least once a month
                        </Label>
                    </FormGroup>

                    <Button 
                        className="continueToRegisterbtn"
                        color="primary"
                        type="submit"
                        onClick={this.handleSubmit}
                        to="register/mentor">Continue
                        </Button>
                    </form>
                </div>
            </div>
        );
    }
}

export default MentorPreRegister;