import './Register.css';

import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Button, FormGroup, Input, Label, FormFeedback } from 'reactstrap';

import MenteeAttributes from '../../components/MenteeAttributes/MenteeAttributes';
import MentorAttributes from '../../components/MentorAttributes/MentorAttributes';
import authService from '../../services/auth';
class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formIsValid: false,
            email: "", emailIsValid: true,
            password: "", passwordIsValid: true,
            passwordAgain: "", passwordAgainIsValid: true,
            Phone: "", PhoneIsValid: true,
            FirstName: "", FirstNameIsValid: true,
            LastName: "", LastNameIsValid: true,
            selectedCareerAreas: [], selectedCareerAreasIsValid: true,
            selectedIndustries: [], selectedIndustriesIsValid: true, 
            CurrentStatusDetails: "", CurrentStatusDetailsIsValid: true, 
            EducationLevel: "1", 
            CurrentStatus: "1",
            BranchOfService: "1",
            Gender: "male",
            YearsOfMilitaryService: "", YearsOfMilitaryServiceIsValid: true,
            ExpectedGraduationDate: "", ExpectedGraduationDateIsValid: true, 
            SeparatedFromActiveDutyDate: "", SeparatedFromActiveDutyDateIsValid: true, 
            ExperienceDescription: "", ExperienceDescriptionIsValid: true,
            CurrentEmployer: "", CurrentEmployerIsValid: true, 
            CurrentJobTitle: "", CurrentJobTitleIsValid: true,
            Background: "", BackgroundIsValid: true, 
            Interests: "", InterestsIsValid: true,
            WhyMentor: "", WhyMentorIsValid: true,
            Interests: "", InterestsIsValid: true,  
            WhyMentee: "", WhyMenteeIsValid: true,  
            LinkedInURL: "",
            userTypeId: "1",
            registered: false,
        };
        this.userTypes = [{ key: "Mentor", value: "1" }, { key: "Mentee", value: "2"}];
        this.GenericAttributes = [ "email", "password", "passwordAgain", "Phone", "FirstName", "LastName"];
        this.MentorAttributes = ["CurrentEmployer", "CurrentJobTitle", "Background", "Interests", "WhyMentor"];
        this.MenteeAttributes = ["CurrentStatusDetails", "ExpectedGraduationDate", "SeparatedFromActiveDutyDate",
            "ExperienceDescription", "YearsOfMilitaryService", "Interests", "WhyMentee"];
        }

    componentDidMount() {
        const { userTypeId } = this.props.match.params;
        this.setState({ userTypeId: userTypeId === "mentee" ? "2" : "1" });
    }

    setSelectedCareerAreas(event) {
        this.setState({ selectedIndustries: event.selectedItems })
    }

    setSelectedIndustries(event) {
        this.setState({ selectedCareerAreas: event.selectedItems })
    }

    attrsChanged(event) {
        this.setState({...event});
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    validateRequiredFields = async (keys) => {
        let isValid = true;
        const formKeys = keys.concat(this.GenericAttributes);
        formKeys.forEach((key) => {
            if (this.state[key] === "") {
                isValid = false;
                this.setState({ [key + "IsValid"]: false });
            } else {
                this.setState({ [key + "IsValid"]: true });
            }
        });
        this.setState({ formIsValid: isValid });
    }

    validatePasswordsMatch = async () => {
        if (this.state.password === "") return;
        if (this.state.password !== this.state.passwordAgain) {
            this.setState({
                passwordAgainIsValid: false,
                formIsValid: false
            });
        } else {
            this.setState({
                passwordAgainIsValid: true,
            });
        }
    }

    validateExperienceIsNotEmpty = async () => {
        if (this.state.selectedCareerAreas.length == 0) {
            this.setState({
                formIsValid: false,
                selectedCareerAreasIsValid: false
            });
        }
        if (this.state.selectedIndustries.length == 0) {
            this.setState({
                formIsValid: false,
                selectedIndustriesIsValid: false
            });
        }
    }

    validateForm = async () => {
        this.validateRequiredFields(this.state.userTypeId == "2" ? this.MenteeAttributes : this.MentorAttributes);
        this.validatePasswordsMatch();
        this.validateExperienceIsNotEmpty();
        return;
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        await this.validateForm();
        if (this.state.formIsValid) {
            try {
                await authService.register({ ...this.state
                });
                this.setState({
                    registered: true
                });
            } catch (error) {
                console.error('Error registering user', error);
            }
        }
    }

    render () {
        const givenUserType = this.props.match.params.userTypeId;
        
        const { userTypeId, registered } = this.state;
        if (registered) {
            return <Redirect to={ `${givenUserType}/complete` } />;
        }
        
        const attributes = userTypeId === "1" ? (
            <MentorAttributes parentState={this.state} onChange={ (e) => this.attrsChanged(e) } />
        ) : (
            <MenteeAttributes parentState={this.state} onChange={ (e) => this.attrsChanged(e) } />
        )
        return (
            <div className="Register">
                <h1>Register - { `${ givenUserType.charAt( 0 ).toUpperCase() }${ givenUserType.substr( 1 ) }` }</h1>
                <form onSubmit={ this.handleSubmit }>
                    <FormGroup id="FirstName">
                        <Label>First Name<span className="RequiredField">*</span></Label>
                        <Input
                            autoFocus
                            type="text"
                            name="FirstName"
                            value={ this.state.FirstName }
                            onChange={ this.handleChange }
                            invalid={!this.state.FirstNameIsValid}
                        />
                         <FormFeedback valid={this.state.FirstNameIsValid}>First Name Is Required</FormFeedback>
                    </FormGroup>

                    <FormGroup id="LastName">
                        <Label>Last Name<span className="RequiredField">*</span></Label>
                        <Input
                            type="text"
                            name="LastName"
                            value={ this.state.LastName }
                            onChange={ this.handleChange }
                            invalid={!this.state.LastNameIsValid}
                        />
                        <FormFeedback valid={this.state.LastNameIsValid}>Last Name Is Required</FormFeedback>

                    </FormGroup>
                    
                    <FormGroup id="email">
                        <Label>Email (Please use your personal email address as opposed to school/work email in the event you graduate/leave your employer, so we can maintain contact)<span className="RequiredField">*</span></Label>
                        <Input
                            type="email"
                            name="email"
                            value={ this.state.email }
                            onChange={ this.handleChange }
                            invalid={!this.state.emailIsValid}
                        />
                        <FormFeedback valid={this.state.emailIsValid}>Email Is Required</FormFeedback>
                    </FormGroup>

                    <FormGroup id="Phone">
                        <Label>Phone<span className="RequiredField">*</span></Label>
                        <Input
                            type="tel"
                            name="Phone"
                            maxLength="10"
                            placeholder="please use format: 1112223333"
                            value={ this.state.Phone }
                            onChange={ this.handleChange }
                            invalid={!this.state.PhoneIsValid}
                            />
                            <FormFeedback valid={this.state.PhoneIsValid}>Phone Is Required</FormFeedback>
                    </FormGroup>

                    <FormGroup id="Gender">
                        <Label>Gender<span className="RequiredField">*</span></Label>
                        <Input
                            type="select"
                            name="Gender"
                            value={ this.state.Gender }
                            onChange={ this.handleChange }>
                            { [{ key: 'Select', value: ''}, { key: 'Male', value: 'male'}, { key: 'Female', value: 'female'}].map( ( e, key ) => (
                                <option key={ key } value={ e.value }>{ e.key }</option>
                            )) }
                        </Input>
                        <FormFeedback valid={this.state.GenderIsValid}>Gender Is Required</FormFeedback>
                    </FormGroup>

                    <FormGroup id="password">
                        <Label>Password<span className="RequiredField">*</span></Label>
                        <Input
                            name="password"
                            value={ this.state.password }
                            onChange={ this.handleChange }
                            type="password"
                            invalid={!this.state.passwordIsValid}
                            />
                            <FormFeedback valid={this.state.passwordIsValid}>Password Is Required</FormFeedback>
                    </FormGroup>

                    <FormGroup id="passwordAgain">
                        <Label>Password Again<span className="RequiredField">*</span></Label>
                        <Input
                            name="passwordAgain"
                            value={ this.state.passwordAgain }
                            onChange={ this.handleChange }
                            type="password"
                            invalid={!this.state.passwordAgainIsValid}
                            />
                            <FormFeedback valid={this.state.passwordAgainIsValid}>Passwords Do Not Match</FormFeedback>
                    </FormGroup>

                    <FormGroup hidden={ givenUserType } id="userTypeId">
                        <Label>Registration Type<span className="RequiredField">*</span></Label>
                        <Input
                            type="select"
                            name="userTypeId"
                            value={ this.state.userTypeId }
                            onChange={ this.handleChange }
                        >
                            { this.userTypes.map( ( e, key ) => (
                                <option key={ key } value={ e.value }>{ e.key }</option>
                            )) }
                        </Input>
                    </FormGroup>
                    
                    { attributes }

                    <Button
                        color="primary"
                        type="submit">Register</Button>
                </form>
            </div>
        );
    }
}

export default withRouter(Register);