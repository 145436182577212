import './ActiveMenteeReport.css';
import React, {Component}  from 'react';
import { Table, Button, Input, FormGroup, Label } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import authService from '../../services/auth';
import reportService from '../../services/report';;

class ActiveMenteeReport extends Component {
  constructor ( props ) {
    super( props );
    this.user = authService.getUserFromToken() || {};
    this.state = {
                  mentees: [],
                  showEmailList: false,
                  emailList: "",
                  showInactive: true
                };
  }

  async componentDidMount() {
    if (!this.user || this.user.userTypeId !== 3) {
      return;
    }
    const mentees = await reportService.getActiveMentees();
    this.setState({ mentees }); 
  }

  exportEmails() {
    let emailList = this.state.mentees.filter(x => {return (!this.state.showInactive) ?  x.IsActive == 1 : x}).map(m => m.EmailAddress).join(", ");
    this.setState({showEmailList: true, emailList});
  }

  filterInactiveList() {
      this.setState({showInactive: !this.state.showInactive});
  }

  render () {
      let {showEmailList} = this.state;
      let rows = this.state.mentees.filter(x => {return (!this.state.showInactive) ?  x.IsActive == 1 : x}).map((m, i) => {
         return (<tr key={i}>
                <td> {m.FirstName}
                 </td>
                 <td> {m.LastName}
                 </td>
                 <td> {m.EmailAddress}
                 </td>
                 <td> {m.IsActive == 1 ? "yes" : "no"}
                 </td>
                 </tr> )
      });
    return (
        <div>
        <FormGroup check>
          <Label check>
          <Input className="cbxhideInactive" type="checkbox" onClick={() => {this.filterInactiveList()}} />Hide Past Mentees
          </Label>
        </FormGroup>
        <Table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Current Mentee</th>
            </tr>
          </thead>
          <tbody>
              {rows}
          </tbody>
      </Table>
      <Button
      name="btnExportEmailList"
      onClick={() => {this.exportEmails()}}
      >Get Email List
    </Button>
    <br/><br/>
         {showEmailList ? <textarea className="emailListTextArea" readOnly value={this.state.emailList}></textarea> : <div></div> }
      </div>
      );
    }
}

export default withRouter(ActiveMenteeReport);