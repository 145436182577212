import cfg from '../cfg'
import { EventEmitter } from 'fbemitter';

class AuthService {
    
    constructor() {
        this.eventEmitter = new EventEmitter();
    }

    on(evt, handler) {
        this.eventEmitter.addListener(evt, handler);
    }

    removeEventListener(eventName, listener) {
        this.eventEmitter.removeListener(eventName, listener);
    }

    emit(event, payload, error = false) {
        this.eventEmitter.emit(event, payload, error);
    }

    async login ( email, password ) {
        const response = await fetch( `${cfg.apiPrefix}api/auth/login`, {
            method: 'POST'
            , headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
            , body: JSON.stringify({ email , password })
        });
        const body = await response.json();
        if ( response.status !== 200 ) throw Error( body.message );
        localStorage.setItem( "overwatch-token", body.token );
        this.emit('loggedin', this.getUserFromToken(body.token));

        return Promise.resolve( body );
        // return Promise.resolve({ auth: true, token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTWF0dCBNaWxsZXIiLCJpYXQiOjE1MjU0NjM2NzgsImV4cCI6MTUyNTQ2NzI3OH0.CsmGa0fQmN9u6k8TZ2OmyAwmrNB4-nazLRz9GvR28Ss"})
    }

    register = async ( user ) => {
        const userModel = this.mapViewModelToModel(user);
        const response = await fetch( `${cfg.apiPrefix}api/auth/register`, {
            method: 'POST'
            , headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
            , body: JSON.stringify( userModel )
        });
        const body = await response.json();
        if ( response.status !== 200 ) throw Error( body.message );

        return Promise.resolve(body);
    }

    mapViewModelToModel (viewModel) {
        return {
            emailAddress: viewModel.email,
            password: viewModel.password,
            userTypeId: viewModel.userTypeId,
            IsVeteran: viewModel.veteranStatus,
            careerExperiences: viewModel.selectedCareerAreas,
            industryExperiences: viewModel.selectedIndustries,
            ...viewModel
        }
    }

    logout () {
        localStorage.removeItem("overwatch-token");
        this.emit('loggedout');
    }

    getToken() {
        return localStorage.getItem("overwatch-token");
    }

    async getUser() {
        //GET auth/me
        return this.getDummyMenteeProfile();
    }

    getDummyMenteeProfile() {
        return Promise.resolve({
            name: "Test Mentee",
            email: "mentee@test.com",
            id: "test_1",
            userTypeId: "2"
        })
    }

    getDummyMentorProfile() {
        return Promise.resolve({
            name: "Test Mentor",
            email: "mentor@test.com",
            id: "test_2",
            userTypeId: "1"
        })
    }

    getDummyStaffProfile() {
        return Promise.resolve({
            name: "Test Staff",
            email: "staff@test.com",
            id: "test_3",
            userTypeId: "3"
        })
    }

    getUserFromToken() {
        const token = this.getToken();
        if (!token || token === '') {
            return null;
        }
        var userInfoEncoded = token.split('.')[1];
        userInfoEncoded = userInfoEncoded.replace('-', '+').replace('_', '/');
        const decoded = JSON.parse(window.atob(userInfoEncoded));
        return decoded;
    }

    resetPassword = async ( password, uuid ) => {
        console.log(uuid)
        const response = await fetch( `${cfg.apiPrefix}api/auth/password/reset`, {
            method: 'POST'
            , headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
            , body: JSON.stringify( {password: password, uuid: uuid} )
        });
        const body = await response.json();
        if ( response.status !== 200 ) throw Error( body.message );

        return Promise.resolve(body);
    }

    sendResetEmail = async ( email ) => {
        const response = await fetch( `${cfg.apiPrefix}api/auth/password/email`, {
            method: 'POST'
            , headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            }
            , body: JSON.stringify( {email: email} )
        });
        const body = await response.json();
        if ( response.status !== 200 ) return Promise.resolve( {error: body.error });

        return Promise.resolve(body);
    }
}
export default new AuthService();