import './Home.css';

import React, { Component } from 'react';

import UserList from '../../components/UserList/UserList';
import userService from '../../services/user';
import caseService from '../../services/cases';
import CaseList from '../../components/CaseList/CaseList';

export default class Home extends Component {

  constructor() {
    super();
    this.state = {
      users: []
    }
  }
  async componentDidMount() {
    this.setState({ users: await userService.getUnapprovedUsers(), cases: await caseService.getActiveCases() });
  }

  render () {
    
    const { users, cases } = this.state;
    
    return (
      <div className="staffHome">
        <label>Users pending approval:</label>
        { users && users.length > 0 ?
          <UserList users={users || []} />
          :
          <div className="alert alert-success">None!</div>
        }
        <label>Active cases:</label>
        <CaseList cases={cases || []} />
      </div>
    );
  }
}
