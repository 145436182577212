import React, { Component } from 'react';
import NotesService from '../../services/notes';
import CreateNoteForm from '../../components/CreateNoteForm/CreateNoteForm';
import NotesList from '../../components/NotesList/NotesList';

import authService from '../../services/auth';

export default class Notes extends Component {

    constructor ( props ) {
        super( props );
        this.user = authService.getUserFromToken() || {};
        this.state = {
            notes: [], 
            showNoteForm: false
        };
      }
    
    
      async componentDidMount() {
        const notes = this.props.notes || await NotesService.getNotes(this.props.caseId);
        this.setState({notes}); 
      }

    noteAdded(note) {
        const { notes } = this.state;
        notes.unshift(note);
        this.setState({notes, showNoteForm: !this.state.showNoteForm});
    }
    
    showFormHandler(e) {
        e.preventDefault();
        this.setState({showNoteForm: false, notes: this.state.notes});
    }

    toggleShowNoteForm = () => {
        const vis = !this.state.showNoteForm;
        this.setState({showNoteForm: vis});
    }
    
    render () {
        const { notes } = this.state;
        return (
            <div>
                {!this.state.showNoteForm ? 
                    <button className="btn btn-primary addNoteButton" onClick={this.toggleShowNoteForm}>Add Note</button>
                : null }

                {this.state.showNoteForm ? <CreateNoteForm action={note => this.noteAdded(note)} case_id={this.props.caseId} notes={ notes }/> : null }
                <NotesList notes={ notes } />
            </div>
        );
    }
}