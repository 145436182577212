import React, { Component } from 'react';
import CasesService from '../../services/cases';
import { ListGroup, ListGroupItem, Button } from 'reactstrap';

export default class CasesNeedingAttention extends Component {

  constructor ( props ) {
    super( props );
    this.state = {
                  cases: [], 
                  showEmailList: false
                };
    this.getCases();
  }
  
    async getCases() {
      //this will be this.props.case_id
        const cases = await CasesService.getCasesNeedingAttention();
        this.setState({
            cases
        });
    }

    exportEmails() {
      let emailList = this.state.cases.map(m => m.EmailAddress).join(", ");
      this.setState({showEmailList: true, emailList});
    }
  
  render () {
    const { cases, showEmailList } = this.state;
    return (
      <div>
        <h3>Cases Needing Attention</h3>
        <p>The following cases' most recent notes are greater than 45 days old</p>
        <ListGroup className="case-list">
        { cases.map( c => (
            <ListGroupItem tag="a"
                href={ `/case/${ c.CaseId }` }
                key={ `case-${ c.CaseId }` }
                className="case-list-item"
            >
               { c.MentorFirstName } { c.MentorLastName } - Last Note Date: {new Intl.DateTimeFormat('en-US', { 
                                    year: 'numeric', 
                                    month: 'long', 
                                    day: '2-digit' }).format(new Date(c.LastNoteDate)) }
            </ListGroupItem>
        )) }  
        </ListGroup>
        <br/><br/>

        <Button
          name="btnExportEmailList"
          onClick={() => {this.exportEmails()}}>Get Email List</Button>
      <br/><br/>
         {showEmailList ? <textarea className="emailListTextArea" readOnly value={this.state.emailList}></textarea> : <div></div> }
        </div>
    );
  }
}
