import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class UserContactInfo extends Component {
    
    constructor ( props ) {
        super( props );
        this.state = {
            user: null
        };
      }

      async componentWillMount() {
          if (this.props.user != null){
            this.setState({user: this.props.user});
          }
    }

    render () {
        const user = this.props;
        const name = `${user.FirstName} ${user.LastName}`;
        const careerExperiences = !!user.CareerExperiences && user.CareerExperiences.length > 0 ? user.CareerExperiences.split(',') : [];
        const industryExperiences = !!user.IndustryExperiences && user.IndustryExperiences.length > 0 ? user.IndustryExperiences.split(',') : [];

        const careerExperienceList = careerExperiences.map(item => {
            return <li>{item}</li>
        });

        const industryExperienceList = industryExperiences.map(item => {
            return <li>{item}</li>
        });

        return (
            <div className="user-contact-info" key={`user-contact-info-key-${user.UserId}`}>
                { !!name && name !== ''
                    ? (
                        <address>
                            <strong>Name</strong>
                            <br />
                            <span className="name">{ name }</span>
                        </address>
                    ) : null
                }                
                { careerExperienceList.length > 0
                    ? (
                        <div>
                            <strong>Career Experiences</strong>
                            <ul>{ careerExperienceList }</ul>
                        </div>
                    ) : null
                }
                { industryExperienceList.length > 0
                    ? (
                        <div>
                            <strong>Industry Experiences</strong>
                            <ul>{ industryExperienceList }</ul>
                        </div>
                    ) : null
                }
            </div>
        );
    }
}

UserContactInfo.propTypes = {
    UserId: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ])
    , FirstName: PropTypes.string
    , LastName: PropTypes.string
    , EmailAddress: PropTypes.string
    , Phone: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ])
    , CareerExperiences: PropTypes.string
    , IndustryExperiences: PropTypes.string
};
