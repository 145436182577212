import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { unregister } from './registerServiceWorker';
import registerServiceWorker from "./registerServiceWorker";
import "./index.css";

ReactDOM.render(
  <Router forceRefresh={ false }>
    <App />
  </Router>,
  document.getElementById("root")
);
unregister();