import './ActiveMenteeReport.css';
import React, {Component}  from 'react';
import { Table, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import authService from '../../services/auth';
import reportService from '../../services/report';
import userService from '../../services/user';

class ActiveMentorsWithNoCase extends Component {
  constructor ( props ) {
    super( props );
    this.user = authService.getUserFromToken() || {};
    this.state = {
                  mentors: [],
                  showEmailList: false,
                  emailList: "",
                };
  }

  async componentDidMount() {
    if (!this.user || this.user.userTypeId !== 3) {
      return;
    }
    const mentors = await reportService.getActiveMentorsWithNoCase();
    this.setState({ mentors }); 
  }

  async deactivateMentor(userId) {
    await userService.setUserInactive(userId);
    const index = this.state.mentors.indexOf(m => { return m.UserId == userId});
    this.state.mentors.splice(index, 1);
    this.setState({mentors: this.state.mentors});
  }

  render () {
      let {showEmailList} = this.state;
      let rows = this.state.mentors.map((m, i) => {
         return (<tr key={i}>
                <td> {m.FirstName}
                 </td>
                 <td> {m.LastName}
                 </td>
                 <td> {m.EmailAddress}
                 </td>
                 <td>
                    <Button
                        name="btnExportEmailList"
                        onClick={() => {this.deactivateMentor(m.UserId)}}>Deactivate Mentor
                    </Button>
                </td>
                 </tr> )
      });
    return (
        <div>
        <Table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
              {rows}
          </tbody>
      </Table>
      
    <br/><br/>
         {showEmailList ? <textarea className="emailListTextArea" readOnly value={this.state.emailList}></textarea> : <div></div> }
      </div>
      );
    }
}

export default withRouter(ActiveMentorsWithNoCase);