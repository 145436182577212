import React, { Component } from 'react';
import NoteService from '../../services/notes';
import { Button, Form, FormGroup, Input, Label, FormFeedback } from "reactstrap";
import './CreateNoteForm.css';


export default class CreateNoteForm extends Component {
   constructor(props) {
       super(props)
       
       this.state = { note: "", noteIsValid: true, formIsValid: false, error: null };
    }

    saveForm = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        
        await this.validateNoteForm();
        this.setState({ error: null });
        if (this.state.formIsValid) {
            const newNote = await NoteService.saveNote(this.props.case_id, this.state.note); // eslint-disable-line
            this.props.action(newNote);
            this.state.note = "";
        }

    }

    validateNoteForm() {
        if (this.state.note.length > 0) {
            this.setState({noteIsValid: true, formIsValid: true});
        } else {
            this.setState({noteIsValid: false, formIsValid: false});
        }
        return;
    }

    handleChange = (event) => {
        this.setState({note: event.target.value});
      }

    render () {
        return (
        <div className="NewNote">
        <Form onSubmit={ this.saveForm }>
            <FormGroup>
                <Label>Add Note</Label>
                <Input id="addNote"
                    type="textarea"
                    name="text"
                    value={ this.state.note }
                    onChange={this.handleChange}
                    invalid={!this.state.noteIsValid}
                    />
                    <FormFeedback valid={this.state.noteIsValid}>Note cannot be empty</FormFeedback>
                </FormGroup>
                <Button
                        className="submitNoteFormButton"
                        type="submit"
                    >
                        Submit
                    </Button>
                    {this.state.error &&
                        <div className="alert alert-danger">{this.state.error}</div>
                    }
                </Form>
            </div>
                );
    }
}

