var api_url = {
    production: "https://api-app.overwatchpartnership.org/",
    stage: "https://vfachuyrv7.execute-api.us-east-1.amazonaws.com/dev/",
    development: "/"
}

var environment = process.env.NODE_ENV === 'production' ? 'production' : process.env.NODE_ENV === 'stage' ? 'stage' : 'development';


export default {
    apiPrefix: api_url[environment]
}