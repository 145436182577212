import React, { Component } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import authService from "../../services/auth";
import coreService from '../../services/core';

import {
    withRouter
  } from "react-router-dom"

import "./Login.css";
class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            password: "",
            error: null,
        };
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange = event => {
        this.setState({
            [ event.target.id ]: event.target.value
        });
    }

    handleSubmit = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        try {
            this.setState({ error: null });
            const userInfo = await this.login(); // eslint-disable-line
            const userType = coreService.userTypes[userInfo.userTypeId.toString()].typeString;
            this.props.history.push( `/${ userType }/home` );
        } catch ( error ) {
            this.setState({ error: error.toString() });
        }
    }

    login = async () => {
        await authService.login( this.state.email, this.state.password );
        return authService.getUserFromToken();
    }
    

    render () {
        return (
            <div className="Login">
                <Form onSubmit={ this.handleSubmit }>
                    <FormGroup>
                        <Label>Email</Label>
                        <Input id="email"
                            autoFocus
                            type="email"
                            value={ this.state.email }
                            onChange={ this.handleChange }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Password</Label>
                        <Input id="password"
                            value={ this.state.password }
                            onChange={ this.handleChange }
                            type="password"
                        />
                    </FormGroup>
                    <Button
                        block
                        disabled={ !this.validateForm() }
                        type="submit"
                    >
                        Login
                    </Button>
                    { this.state.error &&
                        <div className="alert alert-danger">{this.state.error}</div>
                    }
                </Form>
                <br/>

                <a href="/password/email" className="resetLink">Password Trouble?</a>
            </div>
        );
    }
}

export default withRouter(Login);