import React, { Component } from 'react';
import userService from '../../services/user';
import UserDetailsList from '../UserDetailsList/UserDetailsList';
import { Input, Label, FormGroup, Row, Col } from 'reactstrap';


export default class AllUsers extends Component {

  constructor() {
    super();
    this.state = {
      users: [],
      filteredUsers: [],
      filterWord: '',
      hideMentees: false,
      hideMentors: false
    }
  }

   handleChange = async event => {
    await this.setState({
      [event.target.name]: event.target.checked, filteredUsers: this.state.users.filter(c => c.FirstName.toLowerCase().includes(this.state.filterWord) || c.LastName.toLowerCase().includes(this.state.filterWord))
    });

    this.filterByMenteeMentor();
  }

  async componentDidMount() {
    var users = await userService.getAllUsers();
    this.setState({ users: users, filteredUsers: users });
  }

  filterList = (event) => {
    let filter = event.target.value.toLowerCase() || "";
    let users = this.state.users.filter(c => c.FirstName.toLowerCase().includes(filter) || c.LastName.toLowerCase().includes(filter));
    this.filterByMenteeMentor();
    this.setState({ filteredUsers: users, filterWord: filter });
  }

  filterByMenteeMentor() {
    if (this.state.hideMentees) {
      this.setState({filteredUsers: this.state.filteredUsers.filter(c => c.UserTypeId == 1)})
    }
    if (this.state.hideMentors) {
      this.setState({filteredUsers: this.state.filteredUsers.filter(c => c.UserTypeId == 2)})
    }
  }

  render() {
    const { filteredUsers } = this.state;

    return (
      <div className="staffHome">
        <form>
          <Row>
          <Col md={3}>
          <FormGroup id="filterUsers" check inline>
            
            <Label>Filter Users</Label>
            <Input
              autoFocus
              type="text"
              name="FilterUsers"
              value={this.state.filterWord}
              onChange={this.filterList}
            />
          </FormGroup>
          </Col>
          <Col md={2}>
          <FormGroup id="hideMentees" check inline>
          <Label check>
            <Input
              autoFocus
              type="checkbox"
              name="hideMentees"
              value={this.state.hideMentees}
              onChange={this.handleChange} />
            Hide Mentees
          </Label>
          </FormGroup>
          </Col>
          <Col md={2}>
          <FormGroup id="hideMentors" check inline>
          <Label check>
            <Input
              autoFocus
              type="checkbox"
              name="hideMentors"
              value={this.state.hideMentors}
              onChange={this.handleChange} />
            Hide Mentors
        </Label>
        </FormGroup>
        </Col>
        </Row>
        </form>
          {filteredUsers && filteredUsers.length > 0 ?
            <UserDetailsList users={filteredUsers || []} />
            :
            <div className="alert alert-success">None!</div>
          }
      </div>
        );
      }
    }
