import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';
import moment from 'moment';

export default class CaseList extends Component {
    render () {
        const { cases } = this.props;
        return (
            <ListGroup className="case-list">
                { cases.map( c => (
                    <ListGroupItem tag="a"
                        href={ `/case/${ c.CaseId }` }
                        key={ `case-${ c.CaseId }` }
                        className="case-list-item"
                    >
                        { c.MentorFirstName } { c.MentorLastName } with { c.MenteeFirstName } { c.MenteeLastName } started { moment(c.StartDate).fromNow() }
                    </ListGroupItem>
                )) }
            </ListGroup>
        );
    }
}

CaseList.propTypes = {
    cases: PropTypes.arrayOf( PropTypes.object )
};

CaseList.defaultProps = {
    cases: []
};
