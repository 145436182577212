import './ActiveMenteeReport.css';
import React, {Component}  from 'react';
import { Table, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import authService from '../../services/auth';
import reportService from '../../services/report';;

class ActiveMentorReport extends Component {
  constructor ( props ) {
    super( props );
    this.user = authService.getUserFromToken() || {};
    this.state = {
                  mentors: [],
                  showEmailList: false,
                  emailList: "",
                };
  }

  async componentDidMount() {
    if (!this.user || this.user.userTypeId !== 3) {
      return;
    }
    const mentors = await reportService.getActiveMentors();
    this.setState({ mentors }); 
  }

  exportEmails() {
    let emailList = this.state.mentors.map(m => m.EmailAddress).join(", ");
    this.setState({showEmailList: true, emailList});
  }

  render () {
      let {showEmailList} = this.state;
      let rows = this.state.mentors.map((m, i) => {
         return (<tr key={i}>
                <td> {m.FirstName}
                 </td>
                 <td> {m.LastName}
                 </td>
                 <td> {m.EmailAddress}
                 </td>
                 </tr> )
      });
    return (
        <div>
        <Table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
              {rows}
          </tbody>
      </Table>
      <Button
      name="btnExportEmailList"
      onClick={() => {this.exportEmails()}}
      >Get Email List
    </Button>
    <br/><br/>
         {showEmailList ? <textarea className="emailListTextArea" readOnly value={this.state.emailList}></textarea> : <div></div> }
      </div>
      );
    }
}

export default withRouter(ActiveMentorReport);