import cfg from '../cfg'
import authService from './auth';
import { BaseService } from './base';

export const userTypeMap = {
  1: 'mentor',
  2: 'mentee',
  3: 'admin'
}

export class UserService extends BaseService {

    async getUnapprovedUsers () {
        const response = await fetch( `${cfg.apiPrefix}api/user/unapprovedList`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
                , 'Access-Control-Allow-Credentials': true
                , "Access-Control-Allow-Headers": "OPTIONS, x-access-token"
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }
        return body;
    }

    async getUserById (id) {
        const response = await fetch( `${cfg.apiPrefix}api/user/${id}`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json',
                'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( ![404, 200].includes(response.status) ) {
            throw Error( body.message );
        }
        
        return body;
    }

    async setUserInactive (id) {
        const response = await fetch( `${cfg.apiPrefix}api/user/${id}`, {
            method: 'DELETE'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }
        return body;
    }

    async setUserActive (id) {
        const response = await fetch( `${cfg.apiPrefix}api/user/activate/${id}`, {
            method: 'POST'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });
        this.refreshTokenFromResponse(response);
        const body = await response.json();
        if ( response.status !== 200 ) {
            throw Error( body.message );
        }
        return body;
    }


    async setUserApproved (id) {
        const response = await fetch( `${cfg.apiPrefix}api/user/${id}/approve`, {
            method: 'PUT'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }
        return body;
    }

    async setUserValidated (token) {
        const response = await fetch( `${cfg.apiPrefix}api/auth/validate/${token}`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        return body;
    }

    async UpdateUser(userId, user) {
        const response = await fetch( `${cfg.apiPrefix}api/user/update/${userId}`, {
            method: 'PUT'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }, 
            body: JSON.stringify( user )
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        return body; 
    }

    async getAllUsers() {
        const response = await fetch( `${cfg.apiPrefix}api/user`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': authService.getToken(),
                'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( ![404, 200].includes(response.status) ) {
            throw Error( body.message );
        }
        return body;
    }
}

export default new UserService();
