import './MenteePreRegister.css';

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, FormGroup, Input, Label } from 'reactstrap';

class MenteePreRegister extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasHonorableDischarge: false,
            activeDutySinceSept11: false,
            validMentee: false,
            invalidMentee: false,
            enableForm: true,
            showFailedMessage: false
        };        
    }

    attrsChanged(event) {
        this.setState({...event});
    }

    handleChange = event => {
        this.setState({
            [ event.target.name ]: event.target.checked
        });
    }

    handleSubmit = async ( event ) => {
        if (this.state.hasHonorableDischarge && this.state.activeDutySinceSept11) {
            this.setState({validMentee: true});
        } else {
            this.setState({showFailedMessage: true})
        }
    }

    render () {
        const { validMentee, showFailedMessage} = this.state;
        if (validMentee) {
            return <Redirect to={ `/register/mentee` } />;
        } 
        if (showFailedMessage) {
            return <Redirect to={`/preregister/menteecriterianotmet`} />;
        }

        return (
            <div>
                <div>
                    <h3>Mentee Registration</h3>
                    <br/>
                    <p>The Overwatch Partnership is currently accepting interested veteran mentees in Ohio. <br/>
                      To qualify for our mentoring program, veterans and active/drilling service members must:</p>
                    <p> (Please select all that apply)</p>
                </div>
            <div className="MenteePreRegister">
                <form>
                    <FormGroup id="activeDutySinceSept11" check>
                    <Label check>
                        <Input
                            autoFocus
                            type="checkbox"
                            name="activeDutySinceSept11"
                            value={ this.state.activeDutySinceSept11 }
                            onChange={ this.handleChange } /> 
                        Have served on active duty after September 11, 2001 (Including reserve/national guard serving on active duty)
                         </Label>
                    </FormGroup>

                    <FormGroup id="hasHonorableDischarge" check>
                        <Label check>
                        <Input
                            type="checkbox"
                            name="hasHonorableDischarge"
                            value={ this.state.hasHonorableDischarge }
                            onChange={ this.handleChange }
                        />
                        Have received an honorable discharge (If you are still on active duty or in the reserve/national guard, disregard and check the box)
                        </Label>
                    </FormGroup>

                    <Button 
                        className="continueToRegisterbtn"
                        color="primary"
                        type="submit"
                        onClick={this.handleSubmit}
                        to="register/mentee">Continue
                        </Button>
                    </form>
                </div>
            </div>
        );
    }
}

export default MenteePreRegister;