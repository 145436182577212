import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardTitle, CardText, CardBody, Col, Pagination, PaginationItem, PaginationLink, Row } from 'reactstrap';
import UserContactInfo from '../UserContactInfo/UserContactInfo';
import casesService from '../../services/cases';

import './MentorGrid.css';

export default class MentorGrid extends Component {
    constructor ( props ) {
        super( props );
        //this.state = {mentors: props.mentors, currentPage: 0};      
        this.state = {currentPage: 0, mentorSelected: false};
        console.log(props)
    }

    async componentDidMount() {
        if (!this.user || this.user.userTypeId === 3) {
          return;
        }
    }

    handleClick(e, index) {
        e.preventDefault();
    
        this.setState({
          currentPage: index
        }); 
    }

    async selectMentor(mentor) {
       await casesService.addCase(mentor.UserId)
        this.props.buttonClick(mentor);
    }

    render () {
        const { currentPage } = this.state;

        const pageSize = 3;
        const pagesCount = Math.ceil(this.props.mentors.length / pageSize); 


        const cards = this.props.mentors.slice(
            currentPage * pageSize,
            (currentPage + 1) * pageSize
          ).map((mentor, i) => {
            const contactProps = { ...mentor };

            return <Col sm="4" key={ `mentor-${ mentor.UserId }` }>
                <Card>
                    <CardBody>
                        <CardTitle>{ mentor.name }</CardTitle>
                        <CardText tag="div">
                            <UserContactInfo { ...contactProps } />
                        </CardText>
                        <Button onClick={() => {this.selectMentor(mentor)}}>Select Mentor</Button>
                    </CardBody>
                </Card>
            </Col>;
        });

        //const { mentors } = this.props;
        return (
            <div>
                <div className="alert alert-info">You are not yet paired with a mentor. Please choose from the list below. 
                Based on our matching criteria, these are the top three mentors for you.
                However, you are welcome to choose any mentor you feel is best for you.</div>
                <h3>Available Mentors</h3>
                <Pagination aria-label="Page navigation example">            
                    <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink
                        onClick={e => this.handleClick(e, currentPage - 1)}
                        previous
                        href="#"
                    />
                    </PaginationItem>

                    {[...Array(pagesCount)].map((page, i) => 
                    <PaginationItem active={i === currentPage} key={i}>
                        <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                        </PaginationLink>
                    </PaginationItem>
                    )}

                    <PaginationItem disabled={currentPage >= pagesCount - 1}>
                    <PaginationLink
                        onClick={e => this.handleClick(e, currentPage + 1)}
                        next
                        href="#"
                    />
                    </PaginationItem>
                </Pagination>
                <Row>
                    { cards }
                </Row>
            </div>
        );
    }
}

MentorGrid.propTypes = {
    mentors: PropTypes.arrayOf( PropTypes.object )
};

MentorGrid.defaultProps = {
    mentors: [
        {
            UserId: null
            , FirstName: null
            , LastName: null
            , EmailAddress: null
            , Phone: null
            , CareerExperiences: null
            , IndustryExperiences: null
        }
    ],
    currentPage: 0
}
