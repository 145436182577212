import './ValidateAccount.css';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import userService from '../../services/user';

class ValidateAccount extends Component {
    
    constructor(props) {
        super(props);
        const { token } = this.props.match.params;
        this.token = token;
        this.state = {
            status: 'pending',
            message: ''
        }
    }

    async componentDidMount() {
        this.validateUser()
    }
    
    async validateUser() {
        const result = await userService.setUserValidated(this.token);
        if (result && result.success) {
            this.setState({ message: '', status: 'ok' })
        }
        else {
            this.setState({ message: (result || { message: ''}).message, status: 'failed' })
        }
    }

    render() {
        const { status, message } = this.state;

        if (status === 'pending')
            return (
                <div className="alert alert-info">
                    Validating account...
                </div>
            )

        if (status === 'failed')
            return (
                <div className="alert alert-danger">
                    { message }
                </div>
            )

        return (
            <div>
                <div className="alert alert-success">
                    You have successfully validated your email address.  Once your account is approved, you will receive an email from us with more details.  Thank you for you interest in the Overwatch Partnership!
                </div>
            </div>
        )
    }
}

export default withRouter(ValidateAccount);