import notesService from './notes';
import cfg from '../cfg';

import authService from './auth';
import { BaseService } from './base';

export class CasesService extends BaseService {

    // async getCases () {
    async getActiveCases () {
        const response = await fetch( `${cfg.apiPrefix}case/active`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });
        this.refreshTokenFromResponse(response);

        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return body;
    }

    // async getCase ( id ) {
    async getCase () {
        const response = await fetch( `${cfg.apiPrefix}case`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });
        this.refreshTokenFromResponse(response);
        if ( response.status === 404 ) {
            return null;
        }
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return body;
    }

    async getCaseById (caseId) {
        console.log('here')
        const response = await fetch( `${cfg.apiPrefix}case/details/${caseId}`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });
        this.refreshTokenFromResponse(response);
        if ( response.status === 404 ) {
            return null;
        }
        const body = await response.json();

        if ( response.status !== 200 ) {
            console.log('error here')
            throw Error( body.message );
            
        }

        return body;
    }

    // async getCaseNotes ( ids ) {
    getCaseNotes ( ids ) {
        // return await Promise.all( ids.map( async id => notesService.getNote( id ) ) );
        return ids.map( id => notesService.getNotes( id ) );
    }

    async getCasesNeedingAttention() {
        const response = await fetch( `${cfg.apiPrefix}case/needsAttention`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return body;
    }

    async getMentorsForMentee() {
        const response = await fetch( `${cfg.apiPrefix}case/mentors`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return body;
    }

    async addCase(userId) {
        const response = await fetch( `${cfg.apiPrefix}case/${userId}`, {
            method: 'POST'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });
        const body = await response.json();

        if ( response.status !== 201 ) {
            throw Error( body.message );
        }

        return;
    }

    async endCase(caseId) {
        const response = await fetch( `${cfg.apiPrefix}case/end/${caseId}`, {
            method: 'POST'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return;
    }
}

export default new CasesService();
