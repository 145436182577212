import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import userService from '../../services/user';
import coreService from '../../services/core';


export default class UserDetailsView extends Component {
   
    constructor(props) {
        super(props);
        const { userId } = this.props.match.params;
        this.userId = userId;
        this.state = {
            user: null,
        }
    }

    async componentDidMount() {
        this.setState({
            user: (await userService.getUserById(this.userId)) || { notFound: true }
        });
    }

    render() {
        const { user } = this.props;
        
        return (
            <div>

            </div>);
    }
}

UserDetailsView.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object)
};

UserDetailsView.defaultProps = {
    users: []
};
