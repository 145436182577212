import React, {Component} from 'react';
import {
  Container,
  Navbar,
  Row,
  Col,
  Nav,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import Routes from './Routes';

import './App.css';
import authService from './services/auth';
import { userTypeMap } from './services/user';

const activeClassName = 'is-active';

class App extends Component {
 
  constructor ( props ) {
    super( props );
    const user = authService.getUserFromToken();
    this.state = {
      isAuthenticated: user !== null,
      user
    };
    authService.on('loggedin', user => {
       this.userHasAuthenticated(true, user);
    });
    authService.on('loggedout', () => {
      this.userHasAuthenticated(false);
   });
  }
  
  userHasAuthenticated = (authenticated, user = null) => {
    this.setState({ isAuthenticated: authenticated, user });
  }

  render () {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      user: this.state.user
    };
    
    return (
      <div className="app">
        <Container fluid={ true } className="app-container">
          <Row noGutters>
            <Col>
              <div className="top-header">
                <NavLink activeClassName={activeClassName} to='/'>
                  <img src="https://s3.amazonaws.com/overwatch-partnership-ui/logo.png" alt=""/>
                </NavLink>
              </div>
            </Col>
          </Row>
          <Row noGutters>
            <Col>
              <Navigation user={childProps.user} isAuthenticated={childProps.isAuthenticated} />
            </Col>
          </Row>
          <div className="content">
            <h3>{}</h3>
            <Routes childProps={ childProps } />
          </div>
        </Container>
      </div>
    );
  }
}

export class Navigation extends Component {

  constructor ( props ) {
    super( props );
    this.state = {
      isAuthenticated: false
      , isOpen: false
    };
  }

  render () {
    const { isAuthenticated, user } = this.props;
    let navLinks = isAuthenticated
      ? (<div class="nav-links">
          <NavLink activeClassName={activeClassName} to={ `${userTypeMap[user.userTypeId]}` }>Hi, { user.name }</NavLink>
          <NavLink activeClassName={activeClassName} to='/logout'>Logout</NavLink></div>
        )
      : ( <NavLink activeClassName={activeClassName} to="/login">Login</NavLink> );
      if (user && user.userTypeId === 3) {
       navLinks =
       <div class="nav-links">
          <NavLink activeClassName={activeClassName} to={ `/${userTypeMap[user.userTypeId]}/home` }>Hi, { user.name }</NavLink>
          <NavLink activeClassName={activeClassName} to='/report/userstats' >User Stats</NavLink>
          <NavLink activeClassName={activeClassName} to='/report/activementee'>Total Mentees</NavLink>
          <NavLink activeClassName={activeClassName} to='/report/menteesearch'>Mentee Search</NavLink>
          <NavLink activeClassName={activeClassName} to='/report/activementor'>Active Mentors</NavLink>
          <NavLink activeClassName={activeClassName} to='/report/inactivementor'>Inactive Mentors</NavLink>
          <NavLink activeClassName={activeClassName} to='/report/mentornocase'>Mentors w/ No Case</NavLink>
          <NavLink activeClassName={activeClassName} to='/case/needsAttention'>Cases Needing Attn</NavLink>
          <NavLink activeClassName={activeClassName} to='/user'>All Users</NavLink>
          <NavLink activeClassName={activeClassName} to='/logout'>Logout</NavLink></div>
      }
    return (
      <Navbar color="dark" dark>
        <Nav>
          { navLinks }
        </Nav>
      </Navbar>
    );
  }
}

export default App;
