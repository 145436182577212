
export class CoreService {
    userTypes = {
        "1": { typeString: "mentor", typeStringProper: "Mentor" },
        "2": { typeString: "mentee", typeStringProper: "Mentee" },
        "3": { typeString: "admin", typeStringProper: "Admin" },
    };

    currentStatuses = [{key: "Active Duty", value: "1" }, {key: "Student", value: "2" }, {key: "Employed", value: "3" },
                        {key: "Veteran Looking For Employment", value: "4" }, ];

    getPossibleCareerAreas() { 
        return [
            { text: "Project Management", id: "1", selected: false },
            { text: "Financial Management", id: "2", selected: false },
            { text: "Information Technology", id: "3", selected: false },
            { text: "Marketing", id: "4", selected: false },
            { text: "Change Management", id: "5", selected: false },
            { text: "Communications", id: "6", selected: false },
            { text: "Sales/Business Development", id: "7", selected: false },
            { text: "Business Process Management", id: "8", selected: false },
        ]
    }

    getPossibleIndustries() {
        return [
            { text: "Government", id: "1", selected: false },
            { text: "Consulting", id: "2", selected: false },
            { text: "Insurance", id: "3", selected: false },
            { text: "Financial Services", id: "4", selected: false },
            { text: "Education", id: "5", selected: false },
            { text: "Nonprofit", id: "6", selected: false },
            { text: "Healthcare", id: "7", selected: false },
            { text: "Manufacturing", id: "11", selected: false },
            { text: "Logistics/Transportation", id: "12", selected: false },
        ]
    }

    veteranOptions = [{key: "No", value: "0" }, {key: "Yes", value: "1" }];
    
    educationLevels = [
        {key: "Some School", value: "1" }, 
        {key: "High School Diploma", value: "2" },
        {key: "Some College", value: "3" },
        {key: "College Degree", value: "4" },
        {key: "Post Graduate Degree", value: "5" },
        {key: "Other Education", value: "6" }
    ];
    serviceBranches = [{key: "Air Force", value: "1" }, {key: "Army", value: "2" }, {key: "Coast Guard", value: "3" }, {key: "Marines", value: "4" }, {key: "Navy", value: "5" }];
}

export default new CoreService();
