import './Home.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';

import MenteeInfo from '../../components/MenteeInfo/MenteeInfo';
import MentorGrid from '../../components/MentorGrid/MentorGrid';
import MentorInfo from '../../components/MentorInfo/MentorInfo';
import authService from '../../services/auth';
import casesService from '../../services/cases';
import Notes from '../../components/Notes/Notes';
import StaffHome from './Staff';

export default class Home extends Component {

  constructor ( props ) {
    super( props );
    this.user = authService.getUserFromToken() || {};
    this.state = {
                  notes: [], 
                  showNoteForm: false,
                  userCase: { notLoaded: true },
                  mentor: null
                };
  }


  async componentDidMount() {
    if (!this.user || this.user.userTypeId === 3) {
      return;
    }
    const userCase = await casesService.getCase();
    if (userCase && userCase.IsActive == 0 && this.user.userTypeId == 2) {
      console.log("Case Ended")
      this.setState({menteeCaseEnded: true});
    }
    if (!userCase) {
      console.log('no userCase assigned');
      this.setState({ userCase: null });
      const mentors = await casesService.getMentorsForMentee();
      console.log(mentors);
      this.setState({mentors});
      return;
    }
    this.setState({userCase }); 
  }

  async setMentorSelection() {
    const userCase = await casesService.getCase();
    this.setState({userCase }); 
  }

  render () {
    const userTypeId = this.props.location.pathname.split( '/' )[ 1 ];
    let body = ( <h1>Overwatch Partnership</h1> );
    const { userCase, menteeCaseEnded } = this.state;
    const hasMentor = userCase && userCase.mentor;
    
    if ( userTypeId !== 'admin' && userCase && userCase.notLoaded) {
      return <div className="alert alert-info">Loading...</div>
    }

    if ( userTypeId === 'mentor' && !userCase) {
      return <div className="alert-alert-info">You are not yet paired with a mentee. You will receive an email once you are paired.</div>
    }

    if (menteeCaseEnded)
    {
      return <div className="alert-alert-info">You have completed your Mentorship!</div>
    }

    if ( userTypeId === 'mentee') {
      body = (
        <div className="mentee-home">
          {
            !hasMentor
              ? ( <MentorGrid buttonClick={mentor => this.setMentorSelection()} mentors={ this.state.mentors } /> )
              : (
                <div>
                  <MentorInfo user={ userCase.mentor } />
                  {/* <NotesList notes={ notes } /> */}
                </div>
              )
          }
        </div>
      );
    } else if ( userTypeId === 'mentor' && userCase.mentee) {
      body = (
        <div className="mentor-home">
          <MenteeInfo user={ userCase.mentee } />
          <Notes caseId={ userCase.CaseId } />
        </div>
      );
    } else if ( userTypeId === 'admin' ) {
      body = (
        <div className="staff-home">
          <StaffHome />
        </div>
      );
    }
    
    return (
      <div className="home">
        { body }
      </div>
    );
  }
}

Home.userCase = null;
Home.user = null;
Home.notes = null;

Home.propTypes = {
  mentors: PropTypes.arrayOf( PropTypes.object )
  , location: PropTypes.object
};

Home.defaultProps = {
  mentors: [
    {
      id: 1
      , name: 'Tobias Fünke'
      , email: 'tobias.funke@mail.com'
      , phone: '(555) 555-5551'
      , address: '1234 Sudden Valley Way'
      , city: 'Indianapolis'
      , state: 'IN'
      , zip: 46268
      , img: 'http://via.placeholder.com/96x96'
    }
    , {
      id: 2
      , name: 'GOB Bluth'
      , email: 'gob.bluth@mail.com'
      , phone: '(555) 555-5552'
      , address: '1234 Maple St'
      , city: 'Indianapolis'
      , state: 'IN'
      , zip: 46268
      , img: 'http://via.placeholder.com/96x96'
    }
    , {
      id: 3
      , name: 'Bob Loblaw'
      , email: 'bob.loblaw@loblawlawblog.com'
      , phone: '(555) 555-5553'
      , address: '1234 Oak St'
      , city: 'Indianapolis'
      , state: 'IN'
      , zip: 46268
      , img: 'http://via.placeholder.com/96x96'
    }
    , {
      id: 4
      , name: 'Gene Parmesan'
      , email: 'gene.parm@privateeye.com'
      , phone: '(555) 555-5554'
      , address: '1234 Ash St'
      , city: 'Indianapolis'
      , state: 'IN'
      , zip: 46268
      , img: 'http://via.placeholder.com/96x96'
    }
    , {
      id: 5
      , name: 'Barry Zuckercorn'
      , email: 'barry@zuckercornlaw.com'
      , phone: '(555) 555-5555'
      , address: '1234 Poplar St'
      , city: 'Indianapolis'
      , state: 'IN'
      , zip: 46268
      , img: 'http://via.placeholder.com/96x96'
    }
    , {
      id: 6
      , name: 'Tom Jane'
      , email: 'tom.jane@mail.com'
      , phone: '(555) 555-5556'
      , address: '1234 Birch St'
      , city: 'Indianapolis'
      , state: 'IN'
      , zip: 46268
      , img: 'http://via.placeholder.com/96x96'
    }
  ]
  , location: null
}
