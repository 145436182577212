import React, { Component } from 'react';
import coreService from '../../services/core'
import { Collapse } from 'reactstrap';
import './index.css'

export default class UserContactInfo extends Component {
    render () {
        const { user } = this.props;
        if (!user) {
            return <div />;
        }
        const interestsString = coreService.getPossibleCareerAreas()
            .filter(a => user.CareerExperiences.map(x => x.id.toString()).includes(a.id))
            .map(i => i.text)
            .join(', ');
        const industryString = coreService.getPossibleIndustries()
            .filter(a => user.IndustryExperience.map(x => x.id.toString()).includes(a.id))
            .map(i => i.text)
            .join(', ');
        if (user.UserTypeId === 2) { // mentee
            return (
                <Collapse isOpen={!this.props.collapse}>
                    <div className="user-profile-info">
                        <div id="CurrentStatus">
                            <label>Current Status</label>
                            <p>{ coreService.currentStatuses.find(s => s.value === user.CurrentStatus).key }</p>
                        </div>
                        <div id="CurrentStatusDetails">
                            <label>Current Status Details</label>
                            <p>{ user.CurrentStatusDetails }</p>
                        </div>
                        <div id="BranchOfService">
                            <label>Branch of Service</label>
                            <p>{ coreService.serviceBranches.find(s => s.value === user.BranchOfService).key }</p>
                        </div>
                        <div id="EducationLevel">
                            <label>Education Level</label>
                            <p>{ coreService.educationLevels.find(s => s.value === user.EducationLevel).key }</p>
                        </div>
                        <div id="ExpectedGraduationDate">
                            <label>Expected Date of College Graduation</label>
                            <p>{ user.ExpectedGraduationDate }</p>
                        </div>
                        <div id="SeparatedFromActiveDutyDate">
                            <label>Date Separated From Active Duty</label>
                            <p>{ user.SeparatedFromActiveDutyDate }</p>
                        </div>
                        <div id="YearsOfMilitaryService">
                            <label>Years of Military Service</label>
                            <p>{ user.YearsOfMilitaryService }</p>
                        </div>
                        <div id="ExperienceDescription">
                            <label>Military/Civilian Experience</label>
                            <p>{ user.ExperienceDescription }</p>
                        </div>

                        <div id="CareerInterests">
                            <label>Career Interests</label>
                            <p>{ interestsString }</p>
                        </div>

                        <div id="IndustryExperience">
                            <label>Industry Interests</label>
                            <p>{ industryString }</p>
                        </div>
                    {/* 
    
                    <FormGroup id="industryExperience">
                        <Label>Industry Interests</Label>
                        <Picker possibleItems={ this.possibleIndustries } 
                            onItemsChanged={ (e) => this.setSelectedIndustries(e) } />
                    </FormGroup>
    
                    <FormGroup id="Interests">
                        <Label>Personal Interests/Hobbies</Label>
                        <Input
                            type="textarea"
                            value={this.state.Interests}
                            onChange={this.handleChange}
                        />
                    </FormGroup>
    
                    <FormGroup id="WhyMentee">
                        <Label>Top three things I'd like to get out of my relationship with a Mentor</Label>
                        <Input
                            type="textarea"
                            value={this.state.WhyMentee}
                            onChange={this.handleChange}
                        />
                    </FormGroup> */}
                </div>
                </Collapse>
            );
        } else if (user.UserTypeId === 1) { // mentor
            return (
                <Collapse isOpen={!this.props.collapse}>
                    <div className="user-profile-info">
                        <div id="CurrentEmployer">
                            <label>Current Employer</label>
                            <p>{ user.CurrentEmployer }</p>
                        </div>

                        <div id="CurrentJobTitle">
                            <label>Current Job Title</label>
                            <p>{ user.CurrentJobTitle }</p>
                        </div>

                        <div id="CareerAreas">
                            <label>Career Areas</label>
                            <p>{ interestsString }</p>
                        </div>

                        <div id="IndustryExperience">
                            <label>Industry Experience</label>
                            <p>{ industryString }</p>
                        </div>
                    </div>
                </Collapse>
                    // <FormGroup id="veteranStatus">
                    //     <Label>Veteran</Label>
                    //     <Input value={ this.state.veteranStatus } type="select" onChange={this.handleChange}>
                    //         {this.veteranOptions.map((e, key) => {
                    //             return <option key={key} value={e.value}>{e.key}</option>;
                    //         })}
                    //     </Input>
                    // </FormGroup>

                    // <FormGroup id="EducationLevel">
                    //     <Label>Education Level</Label>
                    //     <Input value={ this.state.EducationLevel } type="select" onChange={this.handleChange}>
                    //         {this.educationLevels.map((e, key) => {
                    //             return <option key={key} value={e.value}>{e.key}</option>;
                    //         })}
                    //     </Input>
                    // </FormGroup>
                    
                    // <FormGroup id="Background">
                    //     <Label>Brief Description of Background and Experiences</Label>
                    //     <Input
                    //         type="textarea"
                    //         value={this.state.Background}
                    //         onChange={this.handleChange}
                    //     />
                    // </FormGroup>

                    // <FormGroup id="Interests">
                    //     <Label>Personal Interests/Hobbies</Label>
                    //     <Input
                    //         type="textarea"
                    //         value={this.state.Interests}
                    //         onChange={this.handleChange}
                    //     />
                    // </FormGroup>
                    
            
                    // <FormGroup id="WhyMentor">
                    //     <Label>Why would you like to become a mentor?</Label>
                    //     <Input
                    //         type="textarea"
                    //         value={this.state.WhyMentor}
                    //         onChange={this.handleChange}
                    //     />
                    // </FormGroup>
            )
        } else {
            // admin
            return <div />;
        }
        
    }
}
