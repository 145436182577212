
import authService from './auth';
import { BaseService } from './base';
import cfg from '../cfg';

export class NotesService extends BaseService {
    // asynce getNotes () {
    async getNotes(case_id) {
        const response = await fetch( `${cfg.apiPrefix}case/notes/${case_id}`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return await Promise.resolve( body );
    }

    async saveNote(case_id, note) {
        const response = await fetch( `${cfg.apiPrefix}case/notes/${case_id}`, {
            method: 'POST'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            },

            body: JSON.stringify({
                Note: note
              })
        });

        this.refreshTokenFromResponse(response);
        const res = await response.json();

        if ( response.status !== 200 ) {
            throw Error( res.message );
        }
        return await Promise.resolve( res[0] );
    }
}

export default new NotesService();
