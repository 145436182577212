import './Complete.css';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Complete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userTypeId: "1"
        };

        this.userTypes = [{key: "Mentor", value: "1"}, {key: "Mentee", value: "2"}];
        
    }

    componentDidMount() {
        const { userTypeId } = this.props.match.params;
        this.setState({ userTypeId: userTypeId === "mentee" ? "2" : "1" });
    }

    handleChange = event => {
        this.setState({
            [ event.target.name ]: event.target.value
        });
    }

    render () {
        const givenUserType = this.props.match.params.userTypeId;
        return (
            <div>
                <h3>Thank you for registering as a { givenUserType }!</h3>
                <p>You should receive an email shortly to verify your email address.</p>
                <a className="btn btn-primary" href="/login">Login</a>
                <p>Once your registration has been approved, you will receive an email from a member of the Overwatch staff with further instructions.</p>
            </div>
        );
    }
}

export default withRouter(Complete);