import './PasswordReset.css';

import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import service from '../../services/auth';

   class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.token = this.props.match.params.uuid;
        this.state = {
            submitDisabled: true,
            password: null,
            passwordAgain: null,
            passwordsMatch: true,
            passwordChanged: false
        };

        console.log(this.token)
    }

    validatePasswordsMatch = async () => {
        if (this.state.password.toString() === this.state.passwordAgain.toString())
        {
            this.setState({passwordsMatch: true});
        } else {
            this.setState({passwordsMatch: false});
        }
    }

     handleSubmit = async  event => {
        event.preventDefault();
        await this.validatePasswordsMatch();
        if (this.state.passwordsMatch) {
            console.log(this)
           await service.resetPassword(this.state.password, this.token)
            this.setState({passwordChanged: true})
        } else {
            return;
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });

        if (this.state.password != null && this.state.passwordAgain != null) {
            this.setState({submitDisabled: false});
        }
    }
   
   render () {
        if (this.state.passwordChanged) return <Redirect to={ `/password/successReset` } />;
        return (
            
            <div>
                <h1>Reset Password</h1>
                <form onSubmit={ this.handleSubmit }>
                    <FormGroup id="password" className="input">
                        <Label>New Password</Label>
                        <Input
                            name="password"
                            value={ this.state.password }
                            onChange={ this.handleChange }
                            type="password"
                            />
                    </FormGroup>

                    <FormGroup id="passwordAgain" className="input">
                        <Label>New Password Again</Label>
                        <Input
                            name="passwordAgain"
                            value={ this.state.passwordAgain }
                            onChange={ this.handleChange }
                            type="password"
                            />
                    </FormGroup>
                    <span className="warningtext" hidden={this.state.passwordsMatch}>Passwords Must Match</span>
                    <br/>
                    <Button
                        disabled={this.state.submitDisabled}
                        color="primary"
                        type="submit">Reset</Button>
                </form>
            </div>
        );
    }
}    

export default withRouter(PasswordReset);