import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';

export default class NotesList extends Component {
    render () {
        const { notes } = this.props;
        return (
            <ListGroup className="notes-list">
                { notes.map( n => (
                    <ListGroupItem
                        className="notes-list-item"
                        key={ `note-${ n.CaseNoteId }` }
                    >
                        <ListGroupItemHeading
                            // href={ `/note/${ n.NoteId }` }
                            tag="a"
                        >
                            { new Intl.DateTimeFormat('en-US', { 
                                    year: 'numeric', 
                                    month: 'long', 
                                    day: '2-digit' }).format(new Date(n.NoteDate)) }
                        </ListGroupItemHeading>

                        <ListGroupItemText>
                            { n.Note }
                        </ListGroupItemText>
                    </ListGroupItem>
                )) }
            </ListGroup>
        );
    }
}

NotesList.propTypes = {
    notes: PropTypes.arrayOf( PropTypes.object )
};

NotesList.defaultProps = {
    notes: []
};
