import './Edit.css';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormGroup, Input, Label } from 'reactstrap';

import MenteeAttributes from '../../components/MenteeAttributes/MenteeAttributes';
import MentorAttributes from '../../components/MentorAttributes/MentorAttributes';
import NotFound from '../../components/NotFound';
import userService from '../../services/user';

class Edit extends Component {
    
    constructor(props) {
        super(props);
        const { userId } = this.props.match.params;
        this.userId = userId;
        this.state = {
            user: null,

            email: "", emailIsValid: true,
            password: "", passwordIsValid: true,
            passwordAgain: "", passwordAgainIsValid: true,
            Phone: "", PhoneIsValid: true,
            FirstName: "", FirstNameIsValid: true,
            LastName: "", LastNameIsValid: true,
            selectedCareerAreas: [], selectedCareerAreasIsValid: true,
            selectedIndustries: [], selectedIndustriesIsValid: true, 
            CurrentStatusDetails: "", CurrentStatusDetailsIsValid: true, 
            YearsOfMilitaryService: "", YearsOfMilitaryServiceIsValid: true,
            ExpectedGraduationDate: "", ExpectedGraduationDateIsValid: true, 
            SeparatedFromActiveDutyDate: "", SeparatedFromActiveDutyDateIsValid: true, 
            ExperienceDescription: "", ExperienceDescriptionIsValid: true,
            CurrentEmployer: "", CurrentEmployerIsValid: true, 
            CurrentJobTitle: "", CurrentJobTitleIsValid: true,
            Background: "", BackgroundIsValid: true, 
            Interests: "", InterestsIsValid: true,
            WhyMentor: "", WhyMentorIsValid: true,
            Interests: "", InterestsIsValid: true,  
            WhyMentee: "", WhyMenteeIsValid: true,  

        }
    }

    async componentDidMount() {
        this.setState({
            user: (await userService.getUserById(this.userId)) || { notFound: true }
        });
    }

    handleChange = event => {
        const { user } = this.state;
        user[event.target.name] = event.target.value;
        this.setState({
            user
        });
    }
    
    async approveUser(e) {
        const result = await userService.setUserApproved(this.userId);
        if (result && result.affectedRows === 1) {
            this.props.history.push('/');
        } else {
            // TODO - error - need notification "popup"
        }
    }

    async denyUser(e) {
        // TODO - add confirm
        const result = await userService.setUserInactive(this.userId);
        if (result && result.affectedRows === 1) {
            this.props.history.push('/');
        } else {
            // TODO - error - need notification "popup"
        }
    }

    render() {
        const { user } = this.state;

        if (!user)
            return (
                <div>
                    Loading...
                </div>
            )

        if (user.notFound) {
            return <NotFound resource={ "User" } />
        }

        const attributes = user.UserTypeId === 1 ? (
            <MentorAttributes user={{...user}} parentState={this.state} onChange={ (e) => this.attrsChanged(e) } />
        ) : user.UserTypeId === 2 ? (
            <MenteeAttributes user={{...user}} parentState={this.state} onChange={ (e) => this.attrsChanged(e) } />
        ) : <div>Staff</div>;

        return (
            <div>
                <button disabled={ user.IsApproved ? 'disabled' : '' } onClick={e => this.approveUser(e)} className="btn btn-outline-success">Approve this user</button>&nbsp;
                <button disabled={ user.IsApproved ? 'disabled' : '' } onClick={e => this.denyUser(e)} className="btn btn-outline-danger">Deny this user</button>
                <form onSubmit={ this.handleSubmit }>
                <FormGroup id="EmailAddress">
                        <Label>Email Address</Label>
                        <Input
                            autoFocus
                            type="text"
                            name="EmailAddress"
                            value={ user.EmailAddress }
                            onChange={ this.handleChange }
                        />
                    </FormGroup>
                    <FormGroup id="FirstName">
                        <Label>First Name</Label>
                        <Input
                            autoFocus
                            type="text"
                            name="FirstName"
                            value={ user.FirstName }
                            onChange={ this.handleChange }
                        />
                    </FormGroup>
                    <FormGroup id="LastName">
                        <Label>Last Name</Label>
                        <Input
                            type="text"
                            name="LastName"
                            value={ user.LastName || '' }
                            onChange={ this.handleChange }
                        />
                    </FormGroup>
                    <FormGroup id="Phone">
                        <Label>Phone</Label>
                        <Input
                            type="tel"
                            name="Phone"
                            value={ user.Phone }
                            onChange={ this.handleChange }
                        />
                    </FormGroup>
                    <FormGroup id="Gender">
                        <Label>Gender</Label>
                        <Input
                            type="select"
                            name="Gender"
                            value={ user.Gender }
                            onChange={ this.handleChange }>
                            { [{ key: 'Select', value: ''}, { key: 'Male', value: 'male'}, { key: 'Female', value: 'female'}].map( ( e, key ) => (
                                <option key={ key } value={ e.value }>{ e.key }</option>
                            )) }
                        </Input>
                    </FormGroup>
                    { attributes }
                </form>
            </div>
        )
    }
}

export default withRouter(Edit);