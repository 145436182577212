import React, { Component } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

export default class Picker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedItems: props.possibleItems.filter(i => i.selected === true)
        }
    }

    toggleSelection(e, area) {
        e.preventDefault();
        e.stopPropagation();
        const { onItemsChanged } = this.props;
        const { selectedItems } = this.state;
        const existing = selectedItems.find(a => a.id === area.id);
        if (existing) {
            selectedItems.splice(selectedItems.indexOf(existing), 1);
        } else {
            selectedItems.push({...area, selected: true });
        }
        this.setState({ selectedItems });
        onItemsChanged({ selectedItems });
    }

    areaIsSelected(areaId) {
        const { selectedItems } = this.state;
        return selectedItems.some(a => a.id === areaId);
    }

    render() {
        const { possibleItems } = this.props;
        return (
            <ListGroup>
                { possibleItems.map(a => (
                    <ListGroupItem itemType="button" onClick={ e => this.toggleSelection(e, a) } key={ a.id } active={ this.areaIsSelected(a.id) }>
                        { a.text }
                    </ListGroupItem> )) }
            </ListGroup>
        )
    }
}