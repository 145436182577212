import './InvalidMentee.css';

import React, { Component } from 'react';
import { NavLink } from 'reactstrap';

class InvalidMentee extends Component {
    render () {
        return (
            <div>
                <h3>Unable To Continue Registration</h3>
                <br/>
                <p>Sorry, In order to qualify for our mentoring program, veterans and active/drilling service members must:</p>
                <p>Have served on active duty after September 11, 2001<br/>
                   Have received an honorable discharge</p>
                <div>
                    <NavLink  className="btn btn-primary" to="/">Back</NavLink>
                </div>
            </div>
        );
    }
}

export default InvalidMentee;