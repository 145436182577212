
import authService from './auth';
import { BaseService } from './base';
import  cfg  from '../cfg';

export class ReportService extends BaseService {
    async getActiveMentees() {
        const response = await fetch( `${cfg.apiPrefix}api/reports/activementees`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return await Promise.resolve( body );
    }

    async getActiveMentors() {
        const response = await fetch( `${cfg.apiPrefix}api/reports/activementors`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return await Promise.resolve( body );
    }

    async getActiveMentorsWithNoCase() {
        const response = await fetch( `${cfg.apiPrefix}api/reports/mentorsnocase`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return await Promise.resolve( body );
    }

    async getInactiveMentors() {
        const response = await fetch( `${cfg.apiPrefix}api/reports/inactivementors`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return await Promise.resolve( body );
    }
    
    async getUserStatsReport(careerExpId, industryExpId) {
        const response = await fetch( `${cfg.apiPrefix}api/reports/getuserstats`, {
            method: 'GET'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            }
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return await Promise.resolve( body[0] );
    }

    async performMenteeSearch(careerExpId, industryExpId) {
        const response = await fetch( `${cfg.apiPrefix}api/reports/menteesearch`, {
            method: 'POST'
            , headers: {
                'Accept': 'application/json'
                , 'Content-Type': 'application/json'
                , 'x-access-token': authService.getToken()
                , 'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({careerExpId: careerExpId, industryExpId: industryExpId})
        });

        this.refreshTokenFromResponse(response);
        const body = await response.json();

        if ( response.status !== 200 ) {
            throw Error( body.message );
        }

        return await Promise.resolve( body );
    }
}

export default new ReportService();
