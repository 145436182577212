import React, {Component}  from 'react';
import { Table } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import authService from '../../services/auth';
import reportService from '../../services/report';
import './UserStatsReport.css';

class UserStatsReport extends Component {
  constructor ( props ) {
    super( props );
    this.user = authService.getUserFromToken() || {};
    this.state = {
                  stats: {}
                };
  }

  async componentDidMount() {
    if (!this.user || this.user.userTypeId !== 3) {
      return;
    }
    const stats = await reportService.getUserStatsReport();
    this.setState({ stats }); 
  }

  render () {
    let {stats} = this.state;

    return (
      <>
      <div className='pt-5 ml-2'>
      <h3 style={{fontWeight: 'bold'}}>Mentees</h3>
      <Table>
          <thead>
            <tr>
              <th width='25%'>Total Mentees</th>
              <th width='25%'>Total Current Mentees</th>
              <th width='25%'></th>
              <th width='25%'></th>
            </tr>
          </thead>
          <tbody>
              <tr>
                  <td>{stats.TotalMentees}</td>
                  <td>{stats.TotalCurrentMentees}</td>
                  <td></td>
                  <td></td>
              </tr>
          </tbody>
      </Table>
      <Table>
          <thead>
            <tr>
              <th width='25%'>College Degree</th>
              <th width='25%'>Avg Years of Service</th>
              <th width='25%'>Male</th>
              <th width='25%'>Female</th>
            </tr>
          </thead>
          <tbody>
              <tr>
                  <td>{Math.round(stats.PercentMenteeDegrees * 100)}%</td>
                  <td>{(parseFloat(stats.AvgYearsOfService)).toFixed(1)}</td>
                  <td>{Math.round(stats.PercentMenteeMales * 100)}%</td>
                  <td>{Math.round(stats.PercentMenteeFemales * 100)}%</td>
              </tr>
          </tbody>
      </Table>
      <h4 style={{fontWeight: 'bold'}}>Branch of Service</h4>
      <Table>
          <thead>
            <tr className='branch'>
              <th>US Air Force</th>
              <th>US Army</th>
              <th>US Coast Guard</th>
              <th>US Marine Corps</th>
              <th>US Navy</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Math.round(stats.PercentMenteeUSAF * 100)}%</td>
              <td>{Math.round(stats.PercentMenteeUSArmy * 100)}%</td>
              <td>{Math.round(stats.PercentMenteeUSCG * 100)}%</td>
              <td>{Math.round(stats.PercentMenteeUSMC * 100)}%</td>
              <td>{Math.round(stats.PercentMenteeUSNavy * 100)}%</td>
            </tr>
          </tbody>
      </Table>
      </div>
      <div className='pt-5 ml-2'>
      <h3 style={{fontWeight: 'bold'}}>Mentors</h3>
      <Table>
          <thead>
            <tr>
              <th width='25%'>Total Mentors</th>
              <th width='25%'>Total Current Mentors</th>
              <th width='25%'>Mentors w/ Case</th>
              <th width='25%'>Mentors w/ No Case</th>
            </tr>
          </thead>
          <tbody>
              <tr>
                  <td>{stats.TotalMentors}</td>
                  <td>{stats.TotalCurrentMentors}</td>
                  <td>{stats.TotalMentorsCase}</td>
                  <td>{stats.TotalMentorsNoCase}</td>
              </tr>
          </tbody>
      </Table>
      <Table>
          <thead>
            <tr>
              <th width='25%'>College Degree</th>
              <th width='25%'>Veteran</th>
              <th width='25%'>Male</th>
              <th width='25%'>Female</th>
            </tr>
          </thead>
          <tbody>
              <tr>
                  <td>{Math.round(stats.PercentMentorDegrees * 100)}%</td>
                  <td>{Math.round(stats.PercentMentorVeterans * 100)}%</td>
                  <td>{Math.round(stats.PercentMentorMales * 100)}%</td>
                  <td>{Math.round(stats.PercentMentorFemales * 100)}%</td>
              </tr>
          </tbody>
      </Table>
      </div>
      </>
      );
    }
}

export default withRouter(UserStatsReport);