import './PasswordReset.css';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

   class PasswordEmailSent extends Component {   
   render () {
        return (
            <div>
                <h3>Password Reset Email Has Been Sent Successfully (Please check your spam folder if you do not see the email)</h3>
            </div>
        );
    }
}    

export default withRouter(PasswordEmailSent);