import './MentorInfo.css';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Jumbotron, Media } from 'reactstrap';

import UserProfileView from '../UserProfileView';

export default class MentorInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDetails: false
        }
    }

    toggleDetails() {
        const { showDetails } = this.state;
        this.setState({ showDetails: !showDetails });
    }

    render () {
        const { user } = this.props;
        console.log(user)
        return (
            <Jumbotron className="mentor-info">
                <h3 className="section-title">Your Mentor</h3>
                <Media>
                    <Media body>
                        <address>
                            <strong>Name</strong>
                            <br />
                            <span className="name">{ `${user.FirstName} ${user.LastName}` }</span>
                        </address>
                        <address>
                        <strong>Email</strong>
                            <br />
                            <a href={ `mailto:${ user.EmailAddress }` }>{ user.EmailAddress }</a>
                        </address>
                        <address>
                            <strong>Phone</strong>
                            <br />
                            <a href={ `tel:+1${ user.Phone }` }>{ user.Phone }</a>
                        </address>
                        <address>
                            <strong>LinkedIn URL</strong>
                            <br />
                            <a style={{display: "table-cell"}} target="_blank" href={user.LinkedInURL ? user.LinkedInURL : ""} className="name">{ `${user.LinkedInURL ? user.LinkedInURL : ""}` }</a>
                        </address>
                        <a className="btn btn-outline-secondary" onClick={() => this.toggleDetails() }>{ this.state.showDetails ? 'Less Details' : 'More Details' }</a>
                        <UserProfileView collapse={ !this.state.showDetails } { ...this.props } />
                    </Media>
                </Media>
            </Jumbotron>
        );
    }
}

MentorInfo.propTypes = {
    id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ])
    , name: PropTypes.string
    , email: PropTypes.string
    , phone: PropTypes.string
    , address: PropTypes.string
    , city: PropTypes.string
    , state: PropTypes.string
    , zip: PropTypes.number
    , img: PropTypes.string
};

MentorInfo.defaultProps = {
    id: 1
    , name: 'Tom Jane'
    , email: 'tom.jane@mail.com'
    , phone: '(555) 555-5555'
    , address: '1234 Maple St'
    , city: 'Indianapolis'
    , state: 'IN'
    , zip: 46268
    , img: 'http://via.placeholder.com/96x96'
};
