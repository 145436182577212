import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import coreService from '../../services/core';


export default class UserDetailsList extends Component {
    render() {
        const { users } = this.props;

        return (
            <div>
                <Table responsive>
                    <tbody>
                        {users.map(u => (
                            <tr key={`user-${u.UserId}`}>
                                <td width="20%"
                                    
                                    tag="a"
                                    className="user-list-item"
                                    href={`/user/${u.UserId}`}
                                    >
                                    {coreService.userTypes[u.UserTypeId.toString()].typeStringProper} - {u.FirstName} {u.LastName}
                                </td>
                                <td width="5%">
                                    {/* <a className="btn btn-outline-secondary"
                                        href={`/userdetails/${u.UserId}`}
                                    >Details</a> */}
                                </td>
                                <td width="5%">
                                    <a tag="a"
                                        className="btn btn-outline-secondary"
                                        href={`/edituser/${u.UserId}`}
                                    >Edit</a>
                                </td>
                                <td></td>

                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>);
    }
}

UserDetailsList.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object)
};

UserDetailsList.defaultProps = {
    users: []
};
