import React, { Component } from 'react';

export default class NotFound extends Component {
    render() {
        const { resource } = this.props;
        return (
            <div>
                {resource} not found.
            </div>
        )
    }
} 