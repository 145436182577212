import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/home/Home";
import Login from "./containers/login/Login";
import Logout from "./containers/logout/Logout";
import Register from "./containers/register/Register";
import RegistrationComplete from "./containers/register/Complete";
import Landing from "./containers/landing/Landing"
import UserEdit from "./containers/user/Edit";
import Case from "./containers/case/Case";
import CasesNeedingAttention from "./containers/casesNeedingAttention/CasesNeedingAttention";
import MenteePreRegister from "./containers/register/MenteePreRegister";
import MentorPreRegister from "./containers/register/MentorPreRegister";
import InvalidMentee from "./containers/register/InvalidMentee";
import InvalidMentor from "./containers/register/InvalidMentor";
import ValidateAccount from "./containers/user/ValidateAccount";
import ActiveMenteeReport from "./containers/report/ActiveMenteeReport";
import ActiveMentorReport from "./containers/report/ActiveMentorReport";
import ActiveMentorsWithNoCase from "./containers/report/ActiveMentorsWithNoCase";
import MenteeSearch from "./containers/report/MenteeSearch";
import PasswordReset from "./containers/passwordReset/PasswordReset";
import PasswordResetEmail from "./containers/passwordReset/PasswordResetEmail";
import PasswordEmailSent from "./containers/passwordReset/PasswordEmailSent";
import PasswordSuccessReset from "./containers/passwordReset/PasswordSuccessReset";
import InactiveMentorReport from "./containers/report/InactiveMentorReport";
import UserStatsReport from "./containers/report/UserStatsReport";
import AllUsers from "./containers/user/AllUsers";
import EditUserDetails from "./components/UserEdit/UserEdit";
import UserDetailsView from "./containers/UserDetailsView/UserDetailsView";

export default (props) => {
  const noUserRoutes = 
  <Switch>
    <Route path="/" exact component={Landing} />
    <Route exact path="/register/:userTypeId/complete" component={RegistrationComplete} />
    <Route path="/register/:userTypeId" component={Register} />
    <Route path="/login" exact component={Login} />
    <Route path="/logout" exact component={Logout} />
    <Route path="/preregister/mentee" component={MenteePreRegister} />
    <Route path="/preregister/mentor" component={MentorPreRegister} />
    <Route path="/preregister/menteecriterianotmet" component={InvalidMentee} />
    <Route path="/preregister/mentorcriterianotmet" component={InvalidMentor} />
    <Route path="/validate/:token" component={ValidateAccount} />
    <Route path="/password/reset/:uuid" component={PasswordReset} />
    <Route path="/password/email" component={PasswordResetEmail} />
    <Route path="/password/sentEmail" component={PasswordEmailSent} />
    <Route path="/password/successReset" component={PasswordSuccessReset} />

  </Switch>;
  const adminRoutes = 
  <Switch>
    <Route path="/" exact component={Landing} />
    <Route path="/logout" exact component={Logout} />
    {/* <Route path="/confirm" exact component={Confirm} /> */}
    <Route path="/:userTypeId/home" component={Home} />
    {/* <Route path="/case/{id}" exact component={Case} /> */}
    <Route path="/user/:userId" component={UserEdit} />
    <Route path="/case/needsAttention" component={CasesNeedingAttention} />
    <Route path="/case/:caseId" component={Case} />
    <Route path="/preregister/mentee" component={MenteePreRegister} />
    <Route path="/preregister/mentor" component={MentorPreRegister} />
    <Route path="/preregister/menteecriterianotmet" component={InvalidMentee} />
    <Route path="/preregister/mentorcriterianotmet" component={InvalidMentor} />
    <Route path="/report/activementee" component={ActiveMenteeReport} />
    <Route path="/report/activementor" component={ActiveMentorReport} />
    <Route path="/report/inactivementor" component={InactiveMentorReport} />
    <Route path="/report/mentornocase" component={ActiveMentorsWithNoCase} />
    <Route path="/report/menteesearch" component={MenteeSearch} />
    <Route path="/report/userstats" component={UserStatsReport} />
    <Route path="/validate/:token" component={ValidateAccount} />
    <Route path="/user" component={AllUsers} />
    <Route path="/edituser/:userId" component={EditUserDetails} />
    <Route path="/userdetails/:userId" component={UserDetailsView}/>
    <Route path="/login" exact component={Login} />
  </Switch>
  const menteeRoutes = 
  <Switch>
    <Route path="/" exact component={Landing} />
    <Route path="/logout" exact component={Logout} />
    {/* <Route path="/confirm" exact component={Confirm} /> */}
    <Route path="/:userTypeId/home" component={Home} />
    {/* <Route path="/case/{id}" exact component={Case} /> */}
    <Route path="/user/:userId" component={UserEdit} />
    <Route path="/case/:caseId" component={Case} />
    <Route path="/preregister/mentee" component={MenteePreRegister} />
    <Route path="/preregister/mentor" component={MentorPreRegister} />
    <Route path="/preregister/menteecriterianotmet" component={InvalidMentee} />
    <Route path="/preregister/mentorcriterianotmet" component={InvalidMentor} />
    <Route path="/validate/:token" component={ValidateAccount} />
    <Route path="/login" exact component={Login} />
  </Switch>
  const mentorRoutes = 
  <Switch>
    <Route path="/" exact component={Landing} />
    <Route path="/logout" exact component={Logout} />
    {/* <Route path="/confirm" exact component={Confirm} /> */}
    <Route path="/:userTypeId/home" component={Home} />
    {/* <Route path="/case/{id}" exact component={Case} /> */}
    <Route path="/user/:userId" component={UserEdit} />
    <Route path="/case/needsAttention" component={CasesNeedingAttention} />
    <Route path="/case/:caseId" component={Case} />
    <Route path="/preregister/mentee" component={MenteePreRegister} />
    <Route path="/preregister/mentor" component={MentorPreRegister} />
    <Route path="/preregister/menteecriterianotmet" component={InvalidMentee} />
    <Route path="/preregister/mentorcriterianotmet" component={InvalidMentor} />
    <Route path="/validate/:token" component={ValidateAccount} />
    <Route path="/login" exact component={Login} />
  </Switch>
  if (!props.childProps.isAuthenticated) {
    return noUserRoutes;
  }
  switch (props.childProps.user.userTypeId.toString()) {
    case "1":
      return mentorRoutes;
    case "2":
      return menteeRoutes;
    case "3":
      return adminRoutes;
    default:
      return null;
  }
}
  