import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, ListGroupItem } from 'reactstrap';
import coreService from '../../services/core';

export default class UserList extends Component {
    render () {
        const { users } = this.props;
        return (
            <ListGroup className="user-list">
                { users.map( u => (
                    <ListGroupItem tag="a"
                        href={ `/user/${ u.UserId }`}
                        key={ `user-${ u.UserId }` }
                        className="user-list-item"
                    >
                        { coreService.userTypes[u.UserTypeId.toString()].typeStringProper } - { u.FirstName } { u.LastName }
                    </ListGroupItem>
                )) }
            </ListGroup>
        );
    }
}

UserList.propTypes = {
    users: PropTypes.arrayOf( PropTypes.object )
};

UserList.defaultProps = {
    users: []
};
