import './PasswordReset.css';

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

   class PasswordSuccessReset extends Component {
  
   render () {
        return (
            <div>
                <h3>Password Has Been Sucessfully Reset</h3>
            </div>
        );
    }
}    

export default withRouter(PasswordSuccessReset);