import React, { Component } from "react";
import { FormGroup, Input, Label, FormFeedback } from "reactstrap"
import Picker from '../../components/Picker';
import coreService from '../../services/core'

export default class MentorAtrributes extends Component {

    constructor(props) {
        super(props);
        if (props.user) {
            this.state = {
                selectedCareerAreas: props.user.CareerExperiences || [],
                selectedIndustries: props.user.IndustryExperience || [],
                EducationLevel: props.user.EducationLevel || null,
                IsVeteran: props.user.IsVeteran,
                CurrentEmployer: props.user.CurrentEmployer || '',
                CurrentJobTitle: props.user.CurrentJobTitle || '',
                LinkedInURL: props.user.LinkedInURL || '',
                Background: props.user.Background || '',
                Interests: props.user.Interests || '',
                WhyMentor: props.user.WhyMentor || ''
            };
        } else {
            this.state = {
                selectedCareerAreas: [],
                selectedIndustries: [],
            };
        }
        
        this.props.parentState.selectedCareerAreas = this.state.selectedCareerAreas;
        this.props.parentState.selectedIndustries = this.state.selectedIndustries;
        this.props.parentState.EducationLevel = this.state.EducationLevel;
        this.props.parentState.IsVeteran = this.state.IsVeteran;
        this.props.parentState.CurrentEmployer = this.state.CurrentEmployer;
        this.props.parentState.CurrentJobTitle = this.state.CurrentJobTitle;
        this.props.parentState.LinkedInURL = this.state.LinkedInURL;
        this.props.parentState.Background = this.state.Background;
        this.props.parentState.Interests = this.state.Interests;
        this.props.parentState.WhyMentor = this.state.WhyMentor;

        this.possibleCareerAreas = coreService.getPossibleCareerAreas();

        this.possibleIndustries = coreService.getPossibleIndustries();

        this.possibleCareerAreas.forEach(a => {
            if (this.state.selectedCareerAreas.findIndex(sa => sa.id.toString() === a.id) > -1)
                a.selected = true;
        })
        this.possibleIndustries.forEach(a => {
            if (this.state.selectedIndustries.findIndex(sa => sa.id.toString() === a.id) > -1)
                a.selected = true;
        })
    }


    async setSelectedCareerAreas(event) {
        await this.setState({ selectedCareerAreas: event.selectedItems })
        const { onChange } = this.props;
        onChange(this.state);
    }

    async setSelectedIndustries(event) {
        await this.setState({ selectedIndustries: event.selectedItems })
        const { onChange } = this.props;
        onChange(this.state);
    }

    handleChange = async event => {
        await this.setState({
            [event.target.parentElement.id]: event.target.value
        });
        const { onChange } = this.props;
        onChange(this.state);
    }

    render() {
        return (
            <div>
                <FormGroup id="LinkedInURL">
                    <Label>LinkedIn URL (This is optional. Please note that if you provide your LinkedIn URL, it will be visible to Mentees when they select their Mentor in our platform)</Label>
                    <Input
                        type="text"
                        value={this.state.LinkedInURL}
                        onChange={this.handleChange}
                        />
                </FormGroup>
                <FormGroup id="CurrentEmployer">
                    <Label>Current Employer<span className="RequiredField">*</span></Label>
                    <Input
                        type="text"
                        value={this.state.CurrentEmployer}
                        onChange={this.handleChange}
                        invalid={ !this.props.parentState.CurrentEmployerIsValid}
                        />
                        <FormFeedback valid={this.props.parentState.CurrentEmployerIsValid}>Current Employer is Required</FormFeedback>
                </FormGroup>

                <FormGroup id="CurrentJobTitle">
                    <Label>Current Job Title<span className="RequiredField">*</span></Label>
                    <Input
                        type="text"
                        value={this.state.CurrentJobTitle}
                        onChange={this.handleChange}
                        invalid={ !this.props.parentState.CurrentJobTitleIsValid}
                        />
                        <FormFeedback valid={this.props.parentState.CurrentJobTitleIsValid}>Current Job Title is Required</FormFeedback>
                </FormGroup>

                <FormGroup id="IsVeteran">
                    <Label>Veteran<span className="RequiredField">*</span></Label>
                    <Input value={ this.state.IsVeteran } type="select" onChange={this.handleChange}>
                        {coreService.veteranOptions.map((e) => {
                            return <option key={e.key} value={e.value}>{e.key}</option>;
                        })}
                    </Input>
                </FormGroup>

                <FormGroup id="EducationLevel">
                    <Label>Education Level<span className="RequiredField">*</span></Label>
                    <Input value={ this.state.EducationLevel } type="select" onChange={this.handleChange}>
                        {coreService.educationLevels.map((e, key) => {
                            return <option key={key} value={e.value}>{e.key}</option>;
                        })}
                    </Input>
                </FormGroup>
                
                <FormGroup id="Background">
                    <Label>Brief Description of Background and Experiences <span className="RequiredField">*</span> <span className="lengthWarning">(Limit 500 Characters)</span></Label>
                    <Input
                        type="textarea"
                        value={this.state.Background}
                        onChange={this.handleChange}
                        invalid={ !this.props.parentState.BackgroundIsValid}
                        maxLength={500}
                        />
                        <FormFeedback valid={this.props.parentState.BackgroundIsValid}>Description is Required</FormFeedback>
                </FormGroup>

                <FormGroup id="Interests">
                    <Label>Personal Interests/Hobbies <span className="RequiredField">*</span> <span className="lengthWarning">(Limit 500 Characters)</span></Label>
                    <Input
                        type="textarea"
                        value={this.state.Interests}
                        onChange={this.handleChange}
                        invalid={ !this.props.parentState.InterestsIsValid}
                        maxLength={500}
                        />
                        <FormFeedback valid={this.props.parentState.InterestsIsValid}>Interests is Required</FormFeedback>
                </FormGroup>
                
                <FormGroup>
                    <Label>Career Experience Areas<span className="RequiredField">*</span></Label>
                    <Picker possibleItems={ this.possibleCareerAreas } 
                        onItemsChanged={ (e) => this.setSelectedCareerAreas(e) } />
                </FormGroup>

                <FormGroup>
                    <Label>Industry Experience<span className="RequiredField">*</span></Label>
                    <Picker possibleItems={ this.possibleIndustries } 
                        onItemsChanged={ (e) => this.setSelectedIndustries(e) } />
                </FormGroup>

                <FormGroup id="WhyMentor">
                    <Label>Why would you like to become a mentor? <span className="RequiredField">*</span> <span className="lengthWarning">(Limit 500 Characters)</span></Label>
                    <Input
                        type="textarea"
                        value={this.state.WhyMentor}
                        onChange={this.handleChange}
                        invalid={!this.props.parentState.WhyMentorIsValid}
                        maxLength={500}
                        />
                        <FormFeedback valid={this.props.parentState.WhyMentorIsValid}>Why would you like... is Required</FormFeedback>
                </FormGroup>
            </div>
        )
    }
}