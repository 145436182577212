

import React, { Component } from 'react';
import { NavLink } from 'reactstrap';

class InvalidMentor extends Component {
    render () {
        return (
            <div>
                <h3>Unable To Continue Registration</h3>
                <br/>
                <p>To become a Mentor in our program, Mentors must:</p>
                <ul>
                    <li>Have at least 5 years of professional experience in the workforce</li>
                    <li>Have a passion to provide mentoring and career development advice to veterans</li>
                    <li>Be willing to meet with assigned Mentee at least once a month</li>
                </ul>
                <div>
                    <NavLink  className="btn btn-primary" href="/">Back</NavLink>
                </div>
            </div>
        );
    }
}

export default InvalidMentor;