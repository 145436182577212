import React, { Component } from "react";
import { Link } from 'react-router-dom'
import authService from "../../services/auth"

import {
    withRouter
  } from "react-router-dom"

import "./Logout.css";
class Logout extends Component {
    
    componentDidMount() {
        authService.logout();
    }

    render() {
        return (
            <div>
                <p>You've been logged out.</p>
                <Link to="/login" className="btn btn-primary">Login</Link>
            </div>
        );
    }
}

export default withRouter(Logout);