import React, { Component } from 'react';
import Notes from '../../components/Notes/Notes';
import CasesService from '../../services/cases';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom'

export default class Case extends Component {
    constructor ( props ) {
        super( props );
        this.state = {
            model: null,
            redirect: false
        }
    }

    async componentDidMount() {
        const { caseId } = this.props.match.params;
        const model = await CasesService.getCaseById(caseId);
        console.log(model);
        this.setState({model}); 
    }

    async endCase(event)
    {
        if (window.confirm('Are you sure you wish to end this case?'))
        {
            await CasesService.endCase(this.state.model.case.CaseId);
            this.setState({redirect: true}); 
        }
    }

    render () {
        const { caseId } = this.props.match.params;
        const { model, redirect } = this.state;
        if (!model) {
            return <div></div>
        }
        if (redirect)
        {
            return <Redirect to='/admin/home' />
        }
        return (
            <div className="home">
                <div className="staff-home">
                    <h3>Case Details</h3>
                    <div><strong>Started {moment(model.case.StartDate).fromNow()}</strong></div>
                    <div className="row">
                        <div className="col">
                            <label>Mentee:&nbsp;</label>
                            <Link to={`/user/${ model.case.MenteeUserId}`}>{model.case.MenteeFirstName} {model.case.MenteeLastName}</Link>
                        </div>
                    </div>
                    <div className="row mt-1 mb-1">
                        <div className="col">
                            <label>Mentor:&nbsp;</label>
                            <Link to={`/user/${ model.case.MentorUserId}`}>{model.case.MentorFirstName} {model.case.MentorLastName}</Link>
                        </div>
                    </div>
                    <Notes notes={model.notes} caseId={ caseId } />
                    <br/>
                    <button className="btn btn-danger" onClick={e => this.endCase(e)}>End Case</button>
                </div>
            </div>
        );
    }
}
