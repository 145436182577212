import './PasswordReset.css';

import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import service from '../../services/auth';

   class PasswordResetEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitDisabled: true,
            email: null,
            error: null,
            success: false
        };
    }

     handleSubmit = async  event => {
        event.preventDefault();
        if (this.state.email) {
            service.sendResetEmail(this.state.email)
            .then(body => {
                if (body.error) {
                    this.setState({error: body.error});
                } else {
                    this.setState({success: true});

                }
            })
        } else {

            return;
        }
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });

        if (this.state.email != null) {
            this.setState({submitDisabled: false});
        }
    }
   
   render () {
    if (this.state.success) return <Redirect to={ `/password/sentEmail` } />;
        return (
            <div>
                <h1>Send Reset Email</h1>
                <form onSubmit={ this.handleSubmit }>
                    <FormGroup id="email" className="input">
                        <Label>Email Address</Label>
                        <Input
                            name="email"
                            value={ this.state.email }
                            onChange={ this.handleChange }
                            type="email"
                            />
                    </FormGroup>
                    <span className="warningtext" hidden={!this.state.error}>This email is not valid</span>
                    <br/>
                    <Button
                        color="primary"
                        type="submit">Send Email</Button>
                </form>
            </div>
        );
    }
}    

export default withRouter(PasswordResetEmail);