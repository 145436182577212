import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Row
  , Col
  , Card
  , CardBody
  , CardTitle
  , CardText
  , Button
} from 'reactstrap';
import authService from '../../services/auth';
import coreService from '../../services/core';

// import './Home.css';

export default class Landing extends Component {

  render () {
    
    const user = authService.getUserFromToken();
    if (user) {
      const { userTypeId } = user;
      const userTypeString = coreService.userTypes[userTypeId.toString()].typeString;
    
      return <Redirect to={ `${ userTypeString }/home` } />;
    }
    
    return (
      <Row>
        <Col md="4">
          <Card>
            <CardBody>
              <CardTitle>Become a Mentee</CardTitle>
              <CardText>
              The Overwatch Partnership is currently accepting interested veteran mentees in Ohio.
              </CardText>
              <Button href="/preregister/mentee">Become a Mentee</Button>
            </CardBody>
          </Card>
        </Col>

        <Col md="4">
          <Card>
            <CardBody>
              <CardTitle>Become a Mentor</CardTitle>
              <CardText>
              The Overwatch Partnership is currently accepting interested veteran Mentors in Ohio.
              </CardText>
              <Button href="/preregister/mentor">Become a Mentor</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );

  }
}