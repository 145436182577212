import React, { Component } from "react";
import { FormGroup, Input, Label, FormFeedback } from "reactstrap"
import Picker from '../../components/Picker';
import coreService from '../../services/core';

export default class MenteeAtrributes extends Component {

    constructor(props) {
        super(props);
        if (props.user) {
            this.state = {
                selectedCareerAreas: props.user.CareerExperiences || [],
                selectedIndustries: props.user.IndustryExperience || [],
                CurrentStatus: props.user.CurrentStatus || null,
                CurrentStatusDetails: props.user.CurrentStatusDetails || '',
                ExpectedGraduationDate: props.user.ExpectedGraduationDate?.split("T")[0] || '',
                BranchOfService: props.user.BranchOfService || null,
                EducationLevel: props.user.EducationLevel || null,
                YearsOfMilitaryService: props.user.YearsOfMilitaryService || '',
                Interests: props.user.Interests || '',
                WhyMentee: props.user.WhyMentee || '',
                ExperienceDescription: props.user.ExperienceDescription || '',
                SeparatedFromActiveDutyDate: props.user.SeparatedFromActiveDutyDate?.split("T")[0] || ''

                // ...props.user
            };
        } else {
            this.state = {
                selectedCareerAreas: [],
                selectedIndustries: [],
                // ...props.user,
            };
        }
        
        this.possibleCareerAreas = coreService.getPossibleCareerAreas();

        this.possibleIndustries = coreService.getPossibleIndustries();

        this.possibleCareerAreas.forEach(a => {
            if (this.state.selectedCareerAreas.findIndex(sa => sa.id.toString() === a.id) > -1)
                a.selected = true;
        })
        this.possibleIndustries.forEach(a => {
            if (this.state.selectedIndustries.findIndex(sa => sa.id.toString() === a.id) > -1)
                a.selected = true;
        })
    }

    async setSelectedCareerAreas(event) {
        await this.setState({ selectedCareerAreas: event.selectedItems })
        const { onChange } = this.props;
        onChange(this.state);
    }

    async setSelectedIndustries(event) {
        await this.setState({ selectedIndustries: event.selectedItems })
        const { onChange } = this.props;
        onChange(this.state);
    }

    handleChange = async event => {
        await this.setState({
            [event.target.parentElement.id]: event.target.value
        });
        const { onChange } = this.props;
        onChange(this.state);
    }

    render() {
        return (
            <div>
                <FormGroup id="CurrentStatus">
                    <Label>Current Status<span className="RequiredField">*</span></Label>
                    <Input value={ this.state.CurrentStatus } type="select" onChange={ this.handleChange }>
                        { coreService.currentStatuses.map( ( e, key ) => (
                            <option key={ key } value={ e.value }>{ e.key }</option>
                        )) }
                    </Input>
                </FormGroup>
                <FormGroup id="CurrentStatusDetails">
                    <Label>Current Status Details <span className="RequiredField">*</span> <span className="lengthWarning">(Limit 500 Characters)</span></Label>
                    <Input
                        type="textarea"
                        value={this.state.CurrentStatusDetails}
                        onChange={this.handleChange}  
                        invalid={ !this.props.parentState.CurrentStatusDetailsIsValid}
                        maxLength={500}
                        />
                        <FormFeedback valid={this.props.parentState.CurrentStatusDetailsIsValid}>Current Status Details is Required</FormFeedback>
                </FormGroup>
                <FormGroup id="BranchOfService">
                    <Label>Branch of Service<span className="RequiredField">*</span></Label>
                    <Input value={ this.state.BranchOfService } type="select" onChange={ this.handleChange }>
                        { coreService.serviceBranches.map( ( e, key ) => (
                            <option key={ key } value={ e.value }>{ e.key }</option>
                        )) }
                    </Input>
                </FormGroup>
                <FormGroup id="EducationLevel">
                    <Label>Education Level<span className="RequiredField">*</span></Label>
                    <Input value={ this.state.EducationLevel } type="select" onChange={this.handleChange}>
                        {coreService.educationLevels.map((e, key) => {
                            return <option key={key} value={e.value}>{e.key}</option>;
                        })}
                    </Input>
                </FormGroup>
                <FormGroup id="ExpectedGraduationDate">
                    <Label>Expected Date of College Graduation<span className="RequiredField">*</span></Label>
                    <Input type="date"
                        value={this.state.ExpectedGraduationDate}
                        onChange={this.handleChange} 
                        invalid={!this.props.parentState.ExpectedGraduationDateIsValid}
                        />
                        <FormFeedback valid={this.props.parentState.ExpectedGraduationDateIsValid}>Expected Date of College Graduation is Required</FormFeedback>
                </FormGroup>
                <FormGroup id="SeparatedFromActiveDutyDate">
                    <Label>Date Separated From Active Duty<span className="RequiredField">*</span></Label>
                    <Input type="date"
                        value={this.state.SeparatedFromActiveDutyDate}
                        onChange={this.handleChange} 
                        invalid={!this.props.parentState.SeparatedFromActiveDutyDateIsValid}
                        />
                        <FormFeedback valid={this.props.parentState.SeparatedFromActiveDutyDateIsValid}>Date Separated From Active Duty is Required</FormFeedback>
                </FormGroup>
                <FormGroup id="YearsOfMilitaryService">
                    <Label>Years of Military Service<span className="RequiredField">*</span></Label>
                    <Input
                        value={this.state.YearsOfMilitaryService}
                        onChange={this.handleChange} 
                        invalid={!this.props.parentState.YearsOfMilitaryServiceIsValid}
                        />
                        <FormFeedback valid={this.props.parentState.YearsOfMilitaryServiceIsValid}>Years of Military Service is Required</FormFeedback>
                </FormGroup>
                <FormGroup id="ExperienceDescription">
                    <Label>Brief Description of Military/Civilian Experience <span className="RequiredField">*</span> <span className="lengthWarning">(Limit 500 Characters)</span></Label>
                    <Input
                        type="textarea"
                        value={this.state.ExperienceDescription}
                        onChange={this.handleChange} 
                        invalid={!this.props.parentState.ExperienceDescriptionIsValid}
                        maxLength={500}
                        />
                        <FormFeedback valid={this.props.parentState.ExperienceDescriptionIsValid}>Brief Description of Military/Civilian Experience is Required</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Career Interests (Please Select At least One)<span className="RequiredField">*</span></Label>
                    <Picker possibleItems={ this.possibleCareerAreas } 
                        onItemsChanged={ (e) => this.setSelectedCareerAreas(e) } />
                </FormGroup>

                <FormGroup id="industryExperience">
                    <Label>Industry Interests (Please Select At least One)<span className="RequiredField">*</span></Label>
                    <Picker possibleItems={ this.possibleIndustries } 
                        onItemsChanged={ (e) => this.setSelectedIndustries(e) } 
                        invalid={!this.props.parentState.selectedIndustriesIsValid}
                        />
                        <FormFeedback valid={this.props.parentState.selectedIndustriesIsValid}>Please Select An Industry</FormFeedback>
                </FormGroup>

                <FormGroup id="Interests">
                    <Label>Personal Interests/Hobbies <span className="RequiredField">*</span> <span className="lengthWarning">(Limit 500 Characters)</span></Label>
                    <Input
                        type="textarea"
                        value={this.state.Interests}
                        onChange={this.handleChange}
                        invalid={!this.props.parentState.InterestsIsValid}
                        maxLength={500}
                        />
                        <FormFeedback valid={this.props.parentState.InterestsIsValid}>Personal Interests/Hobbies is Required</FormFeedback>
                </FormGroup>

                <FormGroup id="WhyMentee">
                    <Label>Top three things I'd like to get out of my relationship with a Mentor <span className="RequiredField">*</span> <span className="lengthWarning">(Limit 500 Characters)</span></Label>
                    <Input
                        type="textarea"
                        value={this.state.WhyMentee}
                        onChange={this.handleChange}
                        invalid={!this.props.parentState.WhyMenteeIsValid}
                        maxLength={500}
                        />
                        <FormFeedback valid={this.props.parentState.WhyMenteeIsValid}>Top Three Things... is Required</FormFeedback>
                </FormGroup>
            </div>
        )
    }
}